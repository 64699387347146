import { useDispatch, useEffectOnce, useHistory } from '@oceanjs/international';
import { useCallback } from 'react';
import { Routes } from '../../../routes/Routes';
import { setLogoutFinished } from '../../../state/userState';

/**
 * Handles logout events. Will destroy the session and redirect
 * the user to the public page.
 */
export const LogoutHandler = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = useCallback(async () => {
    await fetch('logout');
    dispatch(setLogoutFinished());
    history.push(Routes.Home);
  }, [dispatch, history]);

  useEffectOnce(() => {
    handleLogout();
  });

  return null;
};
