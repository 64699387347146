import { Route } from '@oceanjs/core';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const GhgImportPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgImportPage').then((module) => ({
    default: module['GhgImportPage'],
  }))
);
const GhgDataSheetPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgDataSheetPage').then((module) => ({
    default: module['GhgDataSheetPage'],
  }))
);
const GhgIntroductionPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgIntroductionPage').then((module) => ({
    default: module['GhgIntroductionPage'],
  }))
);
const GhgDocumentsPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgDocumentsPage').then((module) => ({
    default: module['GhgDocumentsPage'],
  }))
);
const GhgSharedDocumentsPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgSharedDocumentsPage').then((module) => ({
    default: module['GhgSharedDocumentsPage'],
  }))
);
const GhgExternalSourcesPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgSourcesPage').then((module) => ({
    default: module['GhgExternalSourcesPage'],
  }))
);
const GhgEmissionsPerSectorPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgEmissionsPerSectorPage').then((module) => ({
    default: module['GhgEmissionsPerSectorPage'],
  }))
);
const GhgEmissionsGraphPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgEmissionsGraphPage').then((module) => ({
    default: module['GhgEmissionsGraphPage'],
  }))
);
const GhgEmissionsPerGasPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgEmissionsPerGasPage').then((module) => ({
    default: module['GhgEmissionsPerGasPage'],
  }))
);
const GhgDataCollectionPage = lazy(() =>
  import('../../pages/app/greenhouse-gas-inventory/GhgDataCollectionPage').then((module) => ({
    default: module['GhgDataCollectionPage'],
  }))
);

/**
 * Contains the route definitions for the GHG module administration
 */
export const ghgRoutes: Route = {
  name: 'GHG',
  path: getLastRoutePart(Routes.AppGhg),
  exact: true,
  routes: [
    {
      name: 'GHG introduction',
      path: getLastRoutePart(Routes.AppGhgIntroduction),
      exact: true,
      component: GhgIntroductionPage,
      routes: [],
    },
    {
      name: 'GHG import',
      path: getLastRoutePart(Routes.AppGhgImport),
      exact: true,
      component: GhgImportPage,
      routes: [],
    },
    {
      name: 'GHG datashheet',
      path: getLastRoutePart(Routes.AppGhgDatasheet),
      exact: true,
      component: GhgDataSheetPage,
      routes: [],
    },
    {
      name: 'GHG emissions graph',
      path: getLastRoutePart(Routes.AppGhgEmissionsGraph),
      exact: true,
      component: GhgEmissionsGraphPage,
      routes: [],
    },
    {
      name: 'GHG emissions per sector',
      path: getLastRoutePart(Routes.AppGhgEmissionsPerSector),
      exact: true,
      component: GhgEmissionsPerSectorPage,
      routes: [],
    },
    {
      name: 'GHG emissions per gas',
      path: getLastRoutePart(Routes.AppGhgEmissionsPerGas),
      exact: true,
      component: GhgEmissionsPerGasPage,
      routes: [],
    },
    {
      name: 'GHG data collection',
      path: getLastRoutePart(Routes.AppGhgDataCollection),
      exact: true,
      component: GhgDataCollectionPage,
      routes: [],
    },
    {
      name: 'GHG documents',
      path: getLastRoutePart(Routes.AppGhgDocuments),
      exact: true,
      component: GhgDocumentsPage,
      routes: [],
    },
    {
      name: 'GHG shared documents',
      path: getLastRoutePart(Routes.AppGhgSharedDocuments),
      exact: true,
      component: GhgSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'GHG external sources',
      path: getLastRoutePart(Routes.AppGhgSources),
      exact: true,
      component: GhgExternalSourcesPage,
      routes: [],
    },
  ],
};
