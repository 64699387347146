import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faSensorCloud } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MyAvailabilityColors } from '../../../../../styles/colors/MyAvailabilityColors';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const CCA_AUTHORITIES = [
  Authority.CCA_INTRODUCTION_R,
  Authority.CCA_INTRODUCTION_U,
  Authority.CCA_ADAPTATION_C,
  Authority.CCA_ADAPTATION_R,
  Authority.CCA_ADAPTATION_U,
  Authority.CCA_ADAPTATION_D,
  Authority.CCA_ADAPTATION_V,
  Authority.CCA_DOCUMENT_C,
  Authority.CCA_DOCUMENT_R,
  Authority.CCA_DOCUMENT_U,
  Authority.CCA_SOURCES_C,
  Authority.CCA_SOURCES_R,
  Authority.CCA_SOURCES_U,
  Authority.CCA_SOURCES_D,
  Authority.CCA_SHARED_DOCUMENT_C,
  Authority.CCA_SHARED_DOCUMENT_R,
  Authority.CCA_SHARED_DOCUMENT_U,
];

/**
 * Menu items for the CCA module
 */
export const CcaMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppCca}
      icon={<FontAwesomeIcon icon={faSensorCloud} />}
      className={MyAvailabilityColors.borderColor}
      text={<FormattedMessage id="menu_cca" defaultMessage="Climate Change Adaptation" />}
      authorities={CCA_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppCcaIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.CCA_ADAPTATION_R, Authority.CCA_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppCcaOverview}
        text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
        authorities={[Authority.CCA_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppCcaAdaptations}
        text={<FormattedMessage id="menu_adaptations" defaultMessage="Adaptations" />}
        authorities={[
          Authority.CCA_ADAPTATION_C,
          Authority.CCA_ADAPTATION_R,
          Authority.CCA_ADAPTATION_U,
          Authority.CCA_ADAPTATION_D,
          Authority.CCA_ADAPTATION_V,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppCcaDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.CCA_DOCUMENT_C, Authority.CCA_DOCUMENT_R, Authority.CCA_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppCcaSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.CCA_SOURCES_C,
          Authority.CCA_SOURCES_R,
          Authority.CCA_SOURCES_U,
          Authority.CCA_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppCcaSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.CCA_SHARED_DOCUMENT_C,
          Authority.CCA_SHARED_DOCUMENT_R,
          Authority.CCA_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
