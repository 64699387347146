import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faSmoke } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const GHG_AUTHORITIES = [
  Authority.CRF_DOCUMENT_R,
  Authority.CRF_DOCUMENT_C,
  Authority.GHG_INTRODUCTION_R,
  Authority.GHG_INTRODUCTION_U,
  Authority.CRF_DOCUMENT_R,
  Authority.CRF_DOCUMENT_C,
  Authority.GHG_EMISSIONS_R,
  Authority.GHG_EMISSIONS_U,
  Authority.GHG_EMISSIONS_GAS_R,
  Authority.GHG_EMISSIONS_GAS_U,
  Authority.GHG_EMISSIONS_SECTOR_R,
  Authority.GHG_EMISSIONS_SECTOR_U,
  Authority.GHG_DOCUMENT_C,
  Authority.GHG_DOCUMENT_R,
  Authority.GHG_DOCUMENT_U,
  Authority.GHG_SOURCES_C,
  Authority.GHG_SOURCES_R,
  Authority.GHG_SOURCES_U,
  Authority.GHG_SOURCES_D,
  Authority.GHG_SHARED_DOCUMENT_C,
  Authority.GHG_SHARED_DOCUMENT_R,
  Authority.GHG_SHARED_DOCUMENT_U,
  Authority.GHG_DATA_COLLECTION_C,
  Authority.GHG_DATA_COLLECTION_R,
  Authority.GHG_DATA_COLLECTION_U,
  Authority.GHG_DATA_COLLECTION_D,
];

/**
 * Menu items for the GHG module
 */
export const GhgMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppGhg}
      icon={<FontAwesomeIcon icon={faSmoke} />}
      className="border-blue-900"
      text={<FormattedMessage id="menu_ghg" defaultMessage="Greenhouse Gas Inventory" />}
      authorities={GHG_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppGhgIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.GHG_INTRODUCTION_R, Authority.GHG_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgImport}
        text={<FormattedMessage id="menu_ghg_import" defaultMessage="GHG emissions import" />}
        authorities={[Authority.CRF_DOCUMENT_R, Authority.CRF_DOCUMENT_C]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgDatasheet}
        text={<FormattedMessage id="menu_ghg_datasheet" defaultMessage="GHG data sheet" />}
        authorities={[Authority.CRF_DOCUMENT_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgEmissionsGraph}
        text={
          <FormattedMessage id="menu_ghg_emissions_graph" defaultMessage="Emissions per sector" />
        }
        authorities={[Authority.GHG_EMISSIONS_R, Authority.GHG_EMISSIONS_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgEmissionsPerGas}
        text={
          <FormattedMessage id="menu_ghg_emissions_per_gas" defaultMessage="Emissions per gas" />
        }
        authorities={[Authority.GHG_EMISSIONS_GAS_R, Authority.GHG_EMISSIONS_GAS_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgEmissionsPerSector}
        text={
          <FormattedMessage
            id="menu_ghg_emissions_per_sector"
            defaultMessage="Sector emission comparison"
          />
        }
        authorities={[Authority.GHG_EMISSIONS_SECTOR_R, Authority.GHG_EMISSIONS_SECTOR_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgDataCollection}
        text={
          <FormattedMessage id="menu_data_collection" defaultMessage="Data collection workspace" />
        }
        authorities={[
          Authority.GHG_DATA_COLLECTION_C,
          Authority.GHG_DATA_COLLECTION_R,
          Authority.GHG_DATA_COLLECTION_U,
          Authority.GHG_DATA_COLLECTION_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.GHG_DOCUMENT_C, Authority.GHG_DOCUMENT_R, Authority.GHG_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.GHG_SOURCES_C,
          Authority.GHG_SOURCES_R,
          Authority.GHG_SOURCES_U,
          Authority.GHG_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppGhgSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.GHG_SHARED_DOCUMENT_C,
          Authority.GHG_SHARED_DOCUMENT_R,
          Authority.GHG_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
