/**
 * Application authorities
 */
export enum Authority {
  USER_ADMINISTRATION_C = 'USER_ADMINISTRATION_C',
  USER_ADMINISTRATION_R = 'USER_ADMINISTRATION_R',
  USER_ADMINISTRATION_U = 'USER_ADMINISTRATION_U',
  USER_ADMINISTRATION_D = 'USER_ADMINISTRATION_D',
  ORGANIZATION_ADMINISTRATION_C = 'ORGANIZATION_ADMINISTRATION_C',
  ORGANIZATION_ADMINISTRATION_R = 'ORGANIZATION_ADMINISTRATION_R',
  ORGANIZATION_ADMINISTRATION_U = 'ORGANIZATION_ADMINISTRATION_U',
  ORGANIZATION_ADMINISTRATION_D = 'ORGANIZATION_ADMINISTRATION_D',
  REPORTING_PERIOD_ADMINISTRATION_C = 'REPORTING_PERIOD_ADMINISTRATION_C',
  REPORTING_PERIOD_ADMINISTRATION_R = 'REPORTING_PERIOD_ADMINISTRATION_R',
  REPORTING_PERIOD_ADMINISTRATION_U = 'REPORTING_PERIOD_ADMINISTRATION_U',
  REPORTING_PERIOD_ADMINISTRATION_D = 'REPORTING_PERIOD_ADMINISTRATION_D',

  MODULE_VISIBILITY_ADMINISTRATION_C = 'MODULE_VISIBILITY_ADMINISTRATION_C',
  MODULE_VISIBILITY_ADMINISTRATION_R = 'MODULE_VISIBILITY_ADMINISTRATION_R',
  MODULE_VISIBILITY_ADMINISTRATION_U = 'MODULE_VISIBILITY_ADMINISTRATION_U',
  MODULE_VISIBILITY_ADMINISTRATION_D = 'MODULE_VISIBILITY_ADMINISTRATION_D',

  DATA_COLLECTION_TEMPLATES_C = 'DATA_COLLECTION_TEMPLATES_C',
  DATA_COLLECTION_TEMPLATES_R = 'DATA_COLLECTION_TEMPLATES_R',
  DATA_COLLECTION_TEMPLATES_U = 'DATA_COLLECTION_TEMPLATES_U',
  DATA_COLLECTION_TEMPLATES_D = 'DATA_COLLECTION_TEMPLATES_D',

  EXTERNAL_APPLICATION_C = 'EXTERNAL_APPLICATION_C',
  EXTERNAL_APPLICATION_R = 'EXTERNAL_APPLICATION_R',
  EXTERNAL_APPLICATION_U = 'EXTERNAL_APPLICATION_U',
  EXTERNAL_APPLICATION_D = 'EXTERNAL_APPLICATION_D',

  INTRODUCTION_U = 'INTRODUCTION_U',

  CRF_DOCUMENT_C = 'CRF_DOCUMENT_C',
  CRF_DOCUMENT_R = 'CRF_DOCUMENT_R',

  NDC_R = 'NDC_R',
  NDC_INTRODUCTION_R = 'NDC_INTRODUCTION_R',
  NDC_INTRODUCTION_U = 'NDC_INTRODUCTION_U',
  NDC_DOCUMENT_C = 'NDC_DOCUMENT_C',
  NDC_DOCUMENT_R = 'NDC_DOCUMENT_R',
  NDC_DOCUMENT_U = 'NDC_DOCUMENT_U',
  NDC_DOCUMENT_D = 'NDC_DOCUMENT_D',
  NDC_SHARED_DOCUMENT_C = 'NDC_SHARED_DOCUMENT_C',
  NDC_SHARED_DOCUMENT_R = 'NDC_SHARED_DOCUMENT_R',
  NDC_SHARED_DOCUMENT_U = 'NDC_SHARED_DOCUMENT_U',
  NDC_SHARED_DOCUMENT_D = 'NDC_SHARED_DOCUMENT_D',
  NDC_SOURCES_C = 'NDC_SOURCES_C',
  NDC_SOURCES_R = 'NDC_SOURCES_R',
  NDC_SOURCES_U = 'NDC_SOURCES_U',
  NDC_SOURCES_D = 'NDC_SOURCES_D',
  NDC_TARGET_R = 'NDC_TARGET_R',
  NDC_TARGET_U = 'NDC_TARGET_U',
  NDC_NATIONAL_CHART_R = 'NDC_NATIONAL_CHART_R',
  NDC_NATIONAL_CHART_U = 'NDC_NATIONAL_CHART_U',
  NDC_SECTOR_CHART_R = 'NDC_SECTOR_CHART_R',
  NDC_SECTOR_CHART_U = 'NDC_SECTOR_CHART_U',
  NDC_RELEVANT_PAM_R = 'NDC_RELEVANT_PAM_R',
  NDC_RELEVANT_CCA_R = 'NDC_RELEVANT_CCA_R',

  GHG_R = 'GHG_R',
  GHG_INTRODUCTION_R = 'GHG_INTRODUCTION_R',
  GHG_INTRODUCTION_U = 'GHG_INTRODUCTION_U',
  GHG_DOCUMENT_C = 'GHG_DOCUMENT_C',
  GHG_DOCUMENT_R = 'GHG_DOCUMENT_R',
  GHG_DOCUMENT_U = 'GHG_DOCUMENT_U',
  GHG_DOCUMENT_D = 'GHG_DOCUMENT_D',
  GHG_SHARED_DOCUMENT_C = 'GHG_SHARED_DOCUMENT_C',
  GHG_SHARED_DOCUMENT_R = 'GHG_SHARED_DOCUMENT_R',
  GHG_SHARED_DOCUMENT_U = 'GHG_SHARED_DOCUMENT_U',
  GHG_SHARED_DOCUMENT_D = 'GHG_SHARED_DOCUMENT_D',
  GHG_SOURCES_C = 'GHG_SOURCES_C',
  GHG_SOURCES_R = 'GHG_SOURCES_R',
  GHG_SOURCES_U = 'GHG_SOURCES_U',
  GHG_SOURCES_D = 'GHG_SOURCES_D',
  GHG_EMISSIONS_R = 'GHG_EMISSIONS_R',
  GHG_EMISSIONS_U = 'GHG_EMISSIONS_U',
  GHG_EMISSIONS_SECTOR_R = 'GHG_EMISSIONS_SECTOR_R',
  GHG_EMISSIONS_SECTOR_U = 'GHG_EMISSIONS_SECTOR_U',
  GHG_EMISSIONS_GAS_R = 'GHG_EMISSIONS_GAS_R',
  GHG_EMISSIONS_GAS_U = 'GHG_EMISSIONS_GAS_U',
  GHG_DATA_COLLECTION_C = 'GHG_DATA_COLLECTION_C',
  GHG_DATA_COLLECTION_R = 'GHG_DATA_COLLECTION_R',
  GHG_DATA_COLLECTION_U = 'GHG_DATA_COLLECTION_U',
  GHG_DATA_COLLECTION_D = 'GHG_DATA_COLLECTION_D',

  PAS_R = 'PAS_R',
  PAS_INTRODUCTION_R = 'PAS_INTRODUCTION_R',
  PAS_INTRODUCTION_U = 'PAS_INTRODUCTION_U',
  PAS_DOCUMENT_C = 'PAS_DOCUMENT_C',
  PAS_DOCUMENT_R = 'PAS_DOCUMENT_R',
  PAS_DOCUMENT_U = 'PAS_DOCUMENT_U',
  PAS_DOCUMENT_D = 'PAS_DOCUMENT_D',
  PAS_SHARED_DOCUMENT_C = 'PAS_SHARED_DOCUMENT_C',
  PAS_SHARED_DOCUMENT_R = 'PAS_SHARED_DOCUMENT_R',
  PAS_SHARED_DOCUMENT_U = 'PAS_SHARED_DOCUMENT_U',
  PAS_SHARED_DOCUMENT_D = 'PAS_SHARED_DOCUMENT_D',
  PAS_SOURCES_C = 'PAS_SOURCES_C',
  PAS_SOURCES_R = 'PAS_SOURCES_R',
  PAS_SOURCES_U = 'PAS_SOURCES_U',
  PAS_SOURCES_D = 'PAS_SOURCES_U',
  PAS_SCENARIO_CHART_R = 'PAS_SCENARIO_CHART_R',
  PAS_SCENARIO_CHART_U = 'PAS_SCENARIO_CHART_U',
  PROJECTION_DOCUMENT_C = 'PROJECTION_DOCUMENT_C',
  PROJECTION_DOCUMENT_R = 'PROJECTION_DOCUMENT_R',

  PAM_R = 'PAM_R',
  PAM_INTRODUCTION_R = 'PAM_INTRODUCTION_R',
  PAM_INTRODUCTION_U = 'PAM_INTRODUCTION_U',
  PAM_ITEM_C = 'PAM_ITEM_C',
  PAM_ITEM_R = 'PAM_ITEM_R',
  PAM_ITEM_U = 'PAM_ITEM_U',
  PAM_ITEM_D = 'PAM_ITEM_D',
  PAM_ITEM_V = 'PAM_ITEM_V',
  PAM_DOCUMENT_C = 'PAM_DOCUMENT_C',
  PAM_DOCUMENT_R = 'PAM_DOCUMENT_R',
  PAM_DOCUMENT_U = 'PAM_DOCUMENT_U',
  PAM_DOCUMENT_D = 'PAM_DOCUMENT_D',
  PAM_SHARED_DOCUMENT_C = 'PAM_SHARED_DOCUMENT_C',
  PAM_SHARED_DOCUMENT_R = 'PAM_SHARED_DOCUMENT_R',
  PAM_SHARED_DOCUMENT_U = 'PAM_SHARED_DOCUMENT_U',
  PAM_SHARED_DOCUMENT_D = 'PAM_SHARED_DOCUMENT_D',
  PAM_SOURCES_C = 'PAM_SOURCES_C',
  PAM_SOURCES_R = 'PAM_SOURCES_R',
  PAM_SOURCES_U = 'PAM_SOURCES_U',
  PAM_SOURCES_D = 'PAM_SOURCES_D',

  CF_R = 'CF_R',
  CF_INTRODUCTION_R = 'CF_INTRODUCTION_R',
  CF_INTRODUCTION_U = 'CF_INTRODUCTION_U',
  CF_DOCUMENT_C = 'CF_DOCUMENT_C',
  CF_DOCUMENT_R = 'CF_DOCUMENT_R',
  CF_DOCUMENT_U = 'CF_DOCUMENT_U',
  CF_DOCUMENT_D = 'CF_DOCUMENT_D',
  CF_SHARED_DOCUMENT_C = 'CF_SHARED_DOCUMENT_C',
  CF_SHARED_DOCUMENT_R = 'CF_SHARED_DOCUMENT_R',
  CF_SHARED_DOCUMENT_U = 'CF_SHARED_DOCUMENT_U',
  CF_SHARED_DOCUMENT_D = 'CF_SHARED_DOCUMENT_D',
  CF_SOURCES_C = 'CF_SOURCES_C',
  CF_SOURCES_R = 'CF_SOURCES_R',
  CF_SOURCES_U = 'CF_SOURCES_U',
  CF_SOURCES_D = 'CF_SOURCES_D',
  CF_ARTICLE9_C = 'CF_ARTICLE9_C',
  CF_ARTICLE9_R = 'CF_ARTICLE9_R',
  CF_ARTICLE9_U = 'CF_ARTICLE9_U',
  CF_ARTICLE9_D = 'CF_ARTICLE9_D',
  CF_ARTICLE9_V = 'CF_ARTICLE9_V',
  CF_ARTICLE10_C = 'CF_ARTICLE10_C',
  CF_ARTICLE10_R = 'CF_ARTICLE10_R',
  CF_ARTICLE10_U = 'CF_ARTICLE10_U',
  CF_ARTICLE10_D = 'CF_ARTICLE10_D',
  CF_ARTICLE10_V = 'CF_ARTICLE10_V',
  CF_ARTICLE11_C = 'CF_ARTICLE11_C',
  CF_ARTICLE11_R = 'CF_ARTICLE11_R',
  CF_ARTICLE11_U = 'CF_ARTICLE11_U',
  CF_ARTICLE11_D = 'CF_ARTICLE11_D',
  CF_ARTICLE11_V = 'CF_ARTICLE11_V',
  CF_ARTICLE13_C = 'CF_ARTICLE13_C',
  CF_ARTICLE13_R = 'CF_ARTICLE13_R',
  CF_ARTICLE13_U = 'CF_ARTICLE13_U',
  CF_ARTICLE13_D = 'CF_ARTICLE13_D',
  CF_ARTICLE13_V = 'CF_ARTICLE13_V',

  CCA_R = 'CCA_R',
  CCA_INTRODUCTION_R = 'CCA_INTRODUCTION_R',
  CCA_INTRODUCTION_U = 'CCA_INTRODUCTION_U',
  CCA_ADAPTATION_C = 'CCA_ADAPTATION_C',
  CCA_ADAPTATION_R = 'CCA_ADAPTATION_R',
  CCA_ADAPTATION_U = 'CCA_ADAPTATION_U',
  CCA_ADAPTATION_D = 'CCA_ADAPTATION_D',
  CCA_ADAPTATION_V = 'CCA_ADAPTATION_V',
  CCA_DOCUMENT_C = 'CCA_DOCUMENT_C',
  CCA_DOCUMENT_R = 'CCA_DOCUMENT_R',
  CCA_DOCUMENT_U = 'CCA_DOCUMENT_U',
  CCA_DOCUMENT_D = 'CCA_DOCUMENT_D',
  CCA_SHARED_DOCUMENT_C = 'CCA_SHARED_DOCUMENT_C',
  CCA_SHARED_DOCUMENT_R = 'CCA_SHARED_DOCUMENT_R',
  CCA_SHARED_DOCUMENT_U = 'CCA_SHARED_DOCUMENT_U',
  CCA_SHARED_DOCUMENT_D = 'CCA_SHARED_DOCUMENT_D',
  CCA_SOURCES_C = 'CCA_SOURCES_C',
  CCA_SOURCES_R = 'CCA_SOURCES_R',
  CCA_SOURCES_U = 'CCA_SOURCES_U',
  CCA_SOURCES_D = 'CCA_SOURCES_D',

  NC_R = 'NC_R',
  NC_RELEVANT_PAM_R = 'NC_RELEVANT_PAM_R',
  NC_RELEVANT_CCA_R = 'NC_RELEVANT_CCA_R',
  NC_DOCUMENT_C = 'NC_DOCUMENT_C',
  NC_DOCUMENT_R = 'NC_DOCUMENT_R',
  NC_DOCUMENT_U = 'NC_DOCUMENT_U',
  NC_DOCUMENT_D = 'NC_DOCUMENT_D',
  NC_SHARED_DOCUMENT_C = 'NC_SHARED_DOCUMENT_C',
  NC_SHARED_DOCUMENT_R = 'NC_SHARED_DOCUMENT_R',
  NC_SHARED_DOCUMENT_U = 'NC_SHARED_DOCUMENT_U',
  NC_SHARED_DOCUMENT_D = 'NC_SHARED_DOCUMENT_D',
  NC_SOURCES_C = 'NC_SOURCES_C',
  NC_SOURCES_R = 'NC_SOURCES_R',
  NC_SOURCES_U = 'NC_SOURCES_U',
  NC_SOURCES_D = 'NC_SOURCES_D',

  INDI_R = 'INDI_R',
  INDI_INTRODUCTION_R = 'INDI_INTRODUCTION_R',
  INDI_INTRODUCTION_U = 'INDI_INTRODUCTION_U',
  INDI_GHG1PRIO_R = 'INDI_GHG1PRIO_R',
  INDI_GHG1PRIO_U = 'INDI_GHG1PRIO_U',
  INDI_GHGAPRIO_R = 'INDI_GHGAPRIO_R',
  INDI_GHGAPRIO_U = 'INDI_GHGAPRIO_U',
  INDI_GHGSUPPL_R = 'INDI_GHGSUPPL_R',
  INDI_GHGSUPPL_U = 'INDI_GHGSUPPL_U',
  INDI_DOCUMENT_C = 'INDI_DOCUMENT_C',
  INDI_DOCUMENT_R = 'INDI_DOCUMENT_R',
  INDI_DOCUMENT_U = 'INDI_DOCUMENT_U',
  INDI_DOCUMENT_D = 'INDI_DOCUMENT_D',
  INDI_SHARED_DOCUMENT_C = 'INDI_SHARED_DOCUMENT_C',
  INDI_SHARED_DOCUMENT_R = 'INDI_SHARED_DOCUMENT_R',
  INDI_SHARED_DOCUMENT_U = 'INDI_SHARED_DOCUMENT_U',
  INDI_SHARED_DOCUMENT_D = 'INDI_SHARED_DOCUMENT_D',
  INDI_SOURCES_C = 'INDI_SOURCES_C',
  INDI_SOURCES_R = 'INDI_SOURCES_R',
  INDI_SOURCES_U = 'INDI_SOURCES_U',
  INDI_SOURCES_D = 'INDI_SOURCES_D',
  INDI_OTHER_C = 'INDI_OTHER_C',
  INDI_OTHER_R = 'INDI_OTHER_R',
  INDI_OTHER_U = 'INDI_OTHER_U',
  INDI_OTHER_D = 'INDI_OTHER_D',
  INDI_CCA_C = 'INDI_CCA_C',
  INDI_CCA_R = 'INDI_CCA_R',
  INDI_CCA_U = 'INDI_CCA_U',
  INDI_CCA_D = 'INDI_CCA_D',
  INDI_PAS_C = 'INDI_PAS_C',
  INDI_PAS_R = 'INDI_PAS_R',
  INDI_PAS_U = 'INDI_PAS_U',
  INDI_PAS_D = 'INDI_PAS_D',
}
