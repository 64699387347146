import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from './Routes';

const PublicInfoPage = lazy(() =>
  import('../pages/public/PublicInfoPage').then((module) => ({ default: module['PublicInfoPage'] }))
);
const LoginPage = lazy(() =>
  import('../pages/public/LoginPage').then((module) => ({ default: module['LoginPage'] }))
);
const UpdatePasswordPage = lazy(() =>
  import('../pages/public/UpdatePasswordPage').then((module) => ({
    default: module['UpdatePasswordPage'],
  }))
);
const ForgotPasswordPage = lazy(() =>
  import('../pages/public/ForgotPasswordPage').then((module) => ({
    default: module['ForgotPasswordPage'],
  }))
);
const PasswordTokenPage = lazy(() =>
  import('../pages/public/PasswordTokenPage').then((module) => ({
    default: module['PasswordTokenPage'],
  }))
);

/**
 * Route definitions for publicly accessible routes
 */
export const publicRoutes: Route[] = [
  {
    name: 'Landing page',
    path: getLastRoutePart(Routes.Home),
    exact: true,
    component: PublicInfoPage,
    routes: [],
  },
  {
    name: 'Login page',
    path: getLastRoutePart(Routes.Login),
    exact: true,
    component: LoginPage,
    routes: [],
  },
  {
    name: 'Update password page',
    path: getLastRoutePart(Routes.UpdatePassword),
    exact: true,
    component: UpdatePasswordPage,
    routes: [],
  },
  {
    name: 'Forgot password page',
    path: getLastRoutePart(Routes.ForgotPassword),
    exact: true,
    component: ForgotPasswordPage,
    routes: [],
  },
  {
    name: 'Reset password by token page',
    path: getLastRoutePart(Routes.PasswordToken),
    exact: true,
    component: PasswordTokenPage,
    routes: [],
  },
];
