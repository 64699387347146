import { darkTheme } from './darkTheme';
import { lightTheme } from './lightTheme';
import { Theme, ThemeNames } from './theme';

/**
 * Contains all supported themes
 */
export const themes: Record<ThemeNames, Theme> = {
  dark: darkTheme,
  light: lightTheme,
};
