type UnauthorizedObserver = () => void;
let unauthorizedObservers: UnauthorizedObserver[] = [];

/**
 * Observer for GraphQL errors due to unauthorized access
 */
export const unauthorizedObserver = {
  /**
   * Adds a listener function to the unauthorized observer
   * @param listener Callback that gehts called when an unauthorized request is sensed
   */
  addListener: (listener: UnauthorizedObserver) => {
    unauthorizedObservers.push(listener);
  },

  /**
   * Removes a listener from the unauthoirzed observer
   * @param listener Callback that gets called when an unauthorized request is sensed
   */
  removeListener: (listener: UnauthorizedObserver) => {
    unauthorizedObservers = unauthorizedObservers.filter((fn) => fn !== listener);
  },

  /**
   * Notifies all listeners about an unauthorized graphQL request
   */
  notifyUnauthorized: () => {
    unauthorizedObservers.forEach((observer) => observer());
  },
};
