import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faCoins } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const CF_AUTHORITIES = [
  Authority.CF_INTRODUCTION_R,
  Authority.CF_INTRODUCTION_U,
  Authority.CF_ARTICLE9_C,
  Authority.CF_ARTICLE9_R,
  Authority.CF_ARTICLE9_U,
  Authority.CF_ARTICLE9_D,
  Authority.CF_ARTICLE10_C,
  Authority.CF_ARTICLE10_R,
  Authority.CF_ARTICLE10_U,
  Authority.CF_ARTICLE10_D,
  Authority.CF_ARTICLE11_C,
  Authority.CF_ARTICLE11_R,
  Authority.CF_ARTICLE11_U,
  Authority.CF_ARTICLE11_D,
  Authority.CF_ARTICLE13_C,
  Authority.CF_ARTICLE13_R,
  Authority.CF_ARTICLE13_U,
  Authority.CF_ARTICLE13_D,
  Authority.CF_DOCUMENT_C,
  Authority.CF_DOCUMENT_R,
  Authority.CF_DOCUMENT_U,
  Authority.CF_SOURCES_C,
  Authority.CF_SOURCES_R,
  Authority.CF_SOURCES_U,
  Authority.CF_SOURCES_D,
  Authority.CF_SHARED_DOCUMENT_C,
  Authority.CF_SHARED_DOCUMENT_R,
  Authority.CF_SHARED_DOCUMENT_U,
];

/**
 * Menu items for the CF module
 */
export const CfMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppClimateFinance}
      icon={<FontAwesomeIcon icon={faCoins} />}
      className="border-teal-800"
      text={<FormattedMessage id="menu_cf" defaultMessage="Climate Finance" />}
      authorities={CF_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.CF_INTRODUCTION_R, Authority.CF_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceOverview}
        text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
        authorities={[Authority.CF_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceArticle9}
        text={<FormattedMessage id="menu_cf_article9" defaultMessage="Financial support" />}
        authorities={[
          Authority.CF_ARTICLE9_C,
          Authority.CF_ARTICLE9_R,
          Authority.CF_ARTICLE9_U,
          Authority.CF_ARTICLE9_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle9Needed}
          text={<FormattedMessage id="menu_needed" defaultMessage="Needed" />}
          authorities={[
            Authority.CF_ARTICLE9_C,
            Authority.CF_ARTICLE9_R,
            Authority.CF_ARTICLE9_U,
            Authority.CF_ARTICLE9_D,
          ]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle9Received}
          text={<FormattedMessage id="menu_received" defaultMessage="Received" />}
          authorities={[
            Authority.CF_ARTICLE9_C,
            Authority.CF_ARTICLE9_R,
            Authority.CF_ARTICLE9_U,
            Authority.CF_ARTICLE9_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceArticle10}
        text={<FormattedMessage id="menu_cf_article10" defaultMessage="Technology support" />}
        authorities={[
          Authority.CF_ARTICLE10_C,
          Authority.CF_ARTICLE10_R,
          Authority.CF_ARTICLE10_U,
          Authority.CF_ARTICLE10_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle10Needed}
          text={<FormattedMessage id="menu_needed" defaultMessage="Needed" />}
          authorities={[
            Authority.CF_ARTICLE10_C,
            Authority.CF_ARTICLE10_R,
            Authority.CF_ARTICLE10_U,
            Authority.CF_ARTICLE10_D,
          ]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle10Received}
          text={<FormattedMessage id="menu_received" defaultMessage="Received" />}
          authorities={[
            Authority.CF_ARTICLE10_C,
            Authority.CF_ARTICLE10_R,
            Authority.CF_ARTICLE10_U,
            Authority.CF_ARTICLE10_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceArticle11}
        text={
          <FormattedMessage id="menu_cf_article11" defaultMessage="Capacity-building support" />
        }
        authorities={[
          Authority.CF_ARTICLE11_C,
          Authority.CF_ARTICLE11_R,
          Authority.CF_ARTICLE11_U,
          Authority.CF_ARTICLE11_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle11Needed}
          text={<FormattedMessage id="menu_needed" defaultMessage="Needed" />}
          authorities={[
            Authority.CF_ARTICLE11_C,
            Authority.CF_ARTICLE11_R,
            Authority.CF_ARTICLE11_U,
            Authority.CF_ARTICLE11_D,
          ]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle11Received}
          text={<FormattedMessage id="menu_received" defaultMessage="Received" />}
          authorities={[
            Authority.CF_ARTICLE11_C,
            Authority.CF_ARTICLE11_R,
            Authority.CF_ARTICLE11_U,
            Authority.CF_ARTICLE11_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceArticle13}
        text={<FormattedMessage id="menu_cf_article13" defaultMessage="Transparency support" />}
        authorities={[
          Authority.CF_ARTICLE13_C,
          Authority.CF_ARTICLE13_R,
          Authority.CF_ARTICLE13_U,
          Authority.CF_ARTICLE13_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppClimateFinanceArticle13Needed}
          text={<FormattedMessage id="menu_needed_received" defaultMessage="Needed and received" />}
          authorities={[
            Authority.CF_ARTICLE13_C,
            Authority.CF_ARTICLE13_R,
            Authority.CF_ARTICLE13_U,
            Authority.CF_ARTICLE13_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.CF_DOCUMENT_C, Authority.CF_DOCUMENT_R, Authority.CF_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.CF_SOURCES_C,
          Authority.CF_SOURCES_R,
          Authority.CF_SOURCES_U,
          Authority.CF_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppClimateFinanceSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.CF_SHARED_DOCUMENT_C,
          Authority.CF_SHARED_DOCUMENT_R,
          Authority.CF_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
