import {
  ChildrenProps,
  Flex,
  MainMenuSecondaryItem as UpstreamComponent,
  MainMenuSecondaryItemProps as UpstreamProps,
} from '@oceanjs/international';
import clsx from 'clsx';
import { Authority } from '../../../../../Authority';
import { useHasAnyAuthority } from '../../../../../state/userState';

type MainMenuSecondaryItemProps = UpstreamProps &
  Partial<ChildrenProps> & {
    /**
     * List of authorities that allow usage of the menu item
     */
    authorities: Authority[];
  };

/**
 * Customized secondary main menu item with permission checks
 */
export const MainMenuSecondaryItem = (props: MainMenuSecondaryItemProps) => {
  const { authorities, children, ...otherProps } = props;
  const canView = useHasAnyAuthority(authorities);

  if (!canView) {
    return null;
  }

  if (children === undefined) {
    return <UpstreamComponent {...otherProps} />;
  }

  return (
    <Flex className={clsx('py-1 last:mb-4')} column>
      <UpstreamComponent {...otherProps} />
      <Flex column className="-mb-4 ml-4">
        {children}
      </Flex>
    </Flex>
  );
};
