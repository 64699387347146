import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faChartMixed } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const INDICATOR_AUTHORITIES = [
  Authority.INDI_R,
  Authority.INDI_INTRODUCTION_R,
  Authority.INDI_INTRODUCTION_U,
  Authority.INDI_GHG1PRIO_R,
  Authority.INDI_GHG1PRIO_U,
  Authority.INDI_GHGAPRIO_R,
  Authority.INDI_GHGAPRIO_U,
  Authority.INDI_GHGSUPPL_R,
  Authority.INDI_GHGSUPPL_U,
  Authority.INDI_DOCUMENT_C,
  Authority.INDI_DOCUMENT_R,
  Authority.INDI_DOCUMENT_U,
  Authority.INDI_DOCUMENT_D,
  Authority.INDI_SHARED_DOCUMENT_C,
  Authority.INDI_SHARED_DOCUMENT_R,
  Authority.INDI_SHARED_DOCUMENT_U,
  Authority.INDI_SHARED_DOCUMENT_D,
  Authority.INDI_SOURCES_C,
  Authority.INDI_SOURCES_R,
  Authority.INDI_SOURCES_U,
  Authority.INDI_SOURCES_D,
  Authority.INDI_OTHER_C,
  Authority.INDI_OTHER_R,
  Authority.INDI_OTHER_U,
  Authority.INDI_OTHER_D,
  Authority.INDI_PAS_C,
  Authority.INDI_PAS_R,
  Authority.INDI_PAS_U,
  Authority.INDI_PAS_D,
  Authority.INDI_CCA_C,
  Authority.INDI_CCA_R,
  Authority.INDI_CCA_U,
  Authority.INDI_CCA_D,
];

/**
 * Menu items for the Indicator module
 */
export const IndicatorMenuitems = () => {
  return (
    <MainMenuItem
      to={Routes.AppIndi}
      icon={<FontAwesomeIcon icon={faChartMixed} />}
      className="border-yellow-900"
      text={<FormattedMessage id="menu_indicator" defaultMessage="Indicators" />}
      authorities={INDICATOR_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppIndiIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.INDI_INTRODUCTION_R, Authority.INDI_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppIndiGhg}
        text={<FormattedMessage id="menu_indicator_ghg" defaultMessage="GHG Indicators" />}
        authorities={[
          Authority.INDI_GHG1PRIO_R,
          Authority.INDI_GHG1PRIO_U,
          Authority.INDI_GHGAPRIO_R,
          Authority.INDI_GHGAPRIO_U,
          Authority.INDI_GHGSUPPL_R,
          Authority.INDI_GHGSUPPL_U,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppIndiGhgOverview}
          text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
          authorities={[
            Authority.INDI_GHG1PRIO_R,
            Authority.INDI_GHGAPRIO_R,
            Authority.INDI_GHGSUPPL_R,
          ]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppIndiGhgPrio1}
          text={<FormattedMessage id="menu_indicator_ghg_prio1" defaultMessage="Priority" />}
          authorities={[Authority.INDI_GHG1PRIO_R, Authority.INDI_GHG1PRIO_U]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppIndiGhgAprio}
          text={
            <FormattedMessage id="menu_indicator_ghg_prio2" defaultMessage="Additional priority" />
          }
          authorities={[Authority.INDI_GHGAPRIO_R, Authority.INDI_GHGAPRIO_U]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppIndiGhgSuppl}
          text={<FormattedMessage id="menu_indicator_ghg_suppl" defaultMessage="Supplementary" />}
          authorities={[Authority.INDI_GHGSUPPL_R, Authority.INDI_GHGSUPPL_U]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppIndiOther}
        text={<FormattedMessage id="menu_indicator_other" defaultMessage="Other Indicators" />}
        authorities={[
          Authority.INDI_OTHER_C,
          Authority.INDI_OTHER_R,
          Authority.INDI_OTHER_U,
          Authority.INDI_OTHER_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppIndiOtherOverview}
          text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
          authorities={[Authority.INDI_OTHER_R]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppIndiOtherData}
          text={
            <FormattedMessage id="menu_indicator_other_data_entry" defaultMessage="Data entry" />
          }
          authorities={[
            Authority.INDI_OTHER_C,
            Authority.INDI_OTHER_R,
            Authority.INDI_OTHER_U,
            Authority.INDI_OTHER_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppIndiPas}
        text={
          <FormattedMessage
            id="menu_indicator_pas"
            defaultMessage="Projections and Scenarios Indicators"
          />
        }
        authorities={[
          Authority.INDI_PAS_C,
          Authority.INDI_PAS_R,
          Authority.INDI_PAS_U,
          Authority.INDI_PAS_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppIndiPasOverview}
          text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
          authorities={[Authority.INDI_PAS_R]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppIndiPasData}
          text={
            <FormattedMessage id="menu_indicator_other_data_entry" defaultMessage="Data entry" />
          }
          authorities={[
            Authority.INDI_PAS_C,
            Authority.INDI_PAS_R,
            Authority.INDI_PAS_U,
            Authority.INDI_PAS_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppIndiCca}
        text={
          <FormattedMessage
            id="menu_indicator_cca"
            defaultMessage="Climate change adaption Indicators"
          />
        }
        authorities={[
          Authority.INDI_CCA_C,
          Authority.INDI_CCA_R,
          Authority.INDI_CCA_U,
          Authority.INDI_CCA_D,
        ]}
      >
        <MainMenuSecondaryItem
          to={Routes.AppIndiCcaOverview}
          text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
          authorities={[Authority.INDI_CCA_R]}
        />
        <MainMenuSecondaryItem
          to={Routes.AppIndiCcaData}
          text={
            <FormattedMessage id="menu_indicator_other_data_entry" defaultMessage="Data entry" />
          }
          authorities={[
            Authority.INDI_CCA_C,
            Authority.INDI_CCA_R,
            Authority.INDI_CCA_U,
            Authority.INDI_CCA_D,
          ]}
        />
      </MainMenuSecondaryItem>
      <MainMenuSecondaryItem
        to={Routes.AppIndiDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[
          Authority.INDI_DOCUMENT_C,
          Authority.INDI_DOCUMENT_R,
          Authority.INDI_DOCUMENT_U,
          Authority.INDI_DOCUMENT_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppIndiSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.INDI_SOURCES_C,
          Authority.INDI_SOURCES_R,
          Authority.INDI_SOURCES_U,
          Authority.INDI_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppIndiSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.INDI_SHARED_DOCUMENT_C,
          Authority.INDI_SHARED_DOCUMENT_R,
          Authority.INDI_SHARED_DOCUMENT_U,
          Authority.INDI_SHARED_DOCUMENT_D,
        ]}
      />
    </MainMenuItem>
  );
};
