/**
 * Defines the language codes of the supported languages
 */
export type SupportedLanguage = 'en-UK' | 'sr' | 'sr-latn';

/**
 * Array containing a list of all supported languages
 */
export const supportedLanguages: SupportedLanguage[] = ['en-UK', 'sr', 'sr-latn'];

/**
 * Map containing the display names of the supported languages.
 *
 * - **key**: language code
 * - **value**: Display name of the language
 */
export const languageDisplayName: Record<SupportedLanguage, string> = {
  'en-UK': 'English',
  sr: 'Ћирилица',
  'sr-latn': 'Latinica',
};
