import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const CfIntroductionPage = lazy(() =>
  import('../../pages/app/climate-finance/CfIntroductionPage').then((module) => ({
    default: module['CfIntroductionPage'],
  }))
);
const CfDocumentsPage = lazy(() =>
  import('../../pages/app/climate-finance/CfDocumentsPage').then((module) => ({
    default: module['CfDocumentsPage'],
  }))
);
const CfSharedDocumentsPage = lazy(() =>
  import('../../pages/app/climate-finance/CfSharedDocumentsPage').then((module) => ({
    default: module['CfSharedDocumentsPage'],
  }))
);
const CfExternalSourcesPage = lazy(() =>
  import('../../pages/app/climate-finance/CfExternalSourcesPage').then((module) => ({
    default: module['CfExternalSourcesPage'],
  }))
);
const CfArticle9IntroductionPage = lazy(() =>
  import('../../pages/app/climate-finance/article-9/CfArticle9IntroductionPage').then((module) => ({
    default: module['CfArticle9IntroductionPage'],
  }))
);
const CfArticle9NeededPage = lazy(() =>
  import('../../pages/app/climate-finance/article-9/CfArticle9NeededPage').then((module) => ({
    default: module['CfArticle9NeededPage'],
  }))
);
const CfArticle9ReceivedPage = lazy(() =>
  import('../../pages/app/climate-finance/article-9/CfArticle9ReceivedPage').then((module) => ({
    default: module['CfArticle9ReceivedPage'],
  }))
);
const CfArticle10IntroductionPage = lazy(() =>
  import('../../pages/app/climate-finance/article-10/CfArticle10IntroductionPage').then(
    (module) => ({
      default: module['CfArticle10IntroductionPage'],
    })
  )
);
const CfArticle10NeededPage = lazy(() =>
  import('../../pages/app/climate-finance/article-10/CfArticle10NeededPage').then((module) => ({
    default: module['CfArticle10NeededPage'],
  }))
);
const CfArticle10ReceivedPage = lazy(() =>
  import('../../pages/app/climate-finance/article-10/CfArticle10ReceivedPage').then((module) => ({
    default: module['CfArticle10ReceivedPage'],
  }))
);
const CfArticle11IntroductionPage = lazy(() =>
  import('../../pages/app/climate-finance/article-11/CfArticle11IntroductionPage').then(
    (module) => ({
      default: module['CfArticle11IntroductionPage'],
    })
  )
);
const CfArticle11NeededPage = lazy(() =>
  import('../../pages/app/climate-finance/article-11/CfArticle11NeededPage').then((module) => ({
    default: module['CfArticle11NeededPage'],
  }))
);
const CfArticle11ReceivedPage = lazy(() =>
  import('../../pages/app/climate-finance/article-11/CfArticle11ReceivedPage').then((module) => ({
    default: module['CfArticle11ReceivedPage'],
  }))
);
const CfArticle13IntroductionPage = lazy(() =>
  import('../../pages/app/climate-finance/article-13/CfArticle13IntroductionPage').then(
    (module) => ({
      default: module['CfArticle13IntroductionPage'],
    })
  )
);
const CfArticle13NeededPage = lazy(() =>
  import('../../pages/app/climate-finance/article-13/CfArticle13NeededPage').then((module) => ({
    default: module['CfArticle13NeededPage'],
  }))
);
const CfOverviewPage = lazy(() =>
  import('../../pages/app/climate-finance/CfOverviewPage').then((module) => ({
    default: module['CfOverviewPage'],
  }))
);

/**
 * Contains the route definitions for the climate finance module administration
 */
export const cfRoutes: Route = {
  name: 'Climate finance',
  path: getLastRoutePart(Routes.AppClimateFinance),
  exact: true,
  routes: [
    {
      name: 'CF introduction',
      path: getLastRoutePart(Routes.AppClimateFinanceIntroduction),
      exact: true,
      component: CfIntroductionPage,
      routes: [],
    },
    {
      name: 'CF overview',
      path: getLastRoutePart(Routes.AppClimateFinanceOverview),
      exact: true,
      component: CfOverviewPage,
      routes: [],
    },
    {
      name: 'CF article 9',
      path: getLastRoutePart(Routes.AppClimateFinanceArticle9),
      exact: true,
      component: CfArticle9IntroductionPage,
      routes: [
        {
          name: 'CF article 9 needed',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle9Needed),
          exact: true,
          component: CfArticle9NeededPage,
          routes: [],
        },
        {
          name: 'CF article 9 received',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle9Received),
          exact: true,
          component: CfArticle9ReceivedPage,
          routes: [],
        },
      ],
    },
    {
      name: 'CF article 10',
      path: getLastRoutePart(Routes.AppClimateFinanceArticle10),
      exact: true,
      component: CfArticle10IntroductionPage,
      routes: [
        {
          name: 'CF article 10 needed',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle10Needed),
          exact: true,
          component: CfArticle10NeededPage,
          routes: [],
        },
        {
          name: 'CF article 10 received',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle10Received),
          exact: true,
          component: CfArticle10ReceivedPage,
          routes: [],
        },
      ],
    },
    {
      name: 'CF article 11',
      path: getLastRoutePart(Routes.AppClimateFinanceArticle11),
      exact: true,
      component: CfArticle11IntroductionPage,
      routes: [
        {
          name: 'CF article 11 needed',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle11Needed),
          exact: true,
          component: CfArticle11NeededPage,
          routes: [],
        },
        {
          name: 'CF article 11 received',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle11Received),
          exact: true,
          component: CfArticle11ReceivedPage,
          routes: [],
        },
      ],
    },
    {
      name: 'CF article 13',
      path: getLastRoutePart(Routes.AppClimateFinanceArticle13),
      exact: true,
      component: CfArticle13IntroductionPage,
      routes: [
        {
          name: 'CF article 13 needed',
          path: getLastRoutePart(Routes.AppClimateFinanceArticle13Needed),
          exact: true,
          component: CfArticle13NeededPage,
          routes: [],
        },
      ],
    },
    {
      name: 'CF documents',
      path: getLastRoutePart(Routes.AppClimateFinanceDocuments),
      exact: true,
      component: CfDocumentsPage,
      routes: [],
    },
    {
      name: 'CF shared documents',
      path: getLastRoutePart(Routes.AppClimateFinanceSharedDocuments),
      exact: true,
      component: CfSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'CF external sources',
      path: getLastRoutePart(Routes.AppClimateFinanceSources),
      exact: true,
      component: CfExternalSourcesPage,
      routes: [],
    },
  ],
};
