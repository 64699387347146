import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, useIntl } from '@oceanjs/international';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAsideClasses } from '../../styles/hooks/asideHooks';

/**
 * Generic loading spinner that is absolutely positioned, uses all possible
 * space and renders a loading circle horizontally and vertically centered
 */
export const LoadingSpinner = () => {
  const intl = useIntl();
  const asideClasses = useAsideClasses();

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeout = window.setTimeout(() => setVisible(true), 200);
    return () => window.clearTimeout(timeout);
  }, []);

  if (!visible) {
    return null;
  }

  return (
    <CSSTransition
      in
      timeout={200}
      unmountOnExit
      classNames={{
        enter: 'opacity-0',
        enterActive: 'opacity-100 transition-opacity duration-200',
        exit: 'opacity-100',
        exitActive: 'opacity-0 transition-opacity duration-200',
      }}
    >
      <Flex alignItems="center" justifyContent="center" className="absolute inset-0">
        <div
          className={clsx(asideClasses, 'rounded-md shadow-overlayPanel p-10')}
          role="alert"
          title={intl.formatMessage({ id: 'loading', defaultMessage: 'Loading' })}
        >
          <FontAwesomeIcon icon={faSpinner} className="text-6xl animate-spin" />
        </div>
      </Flex>
    </CSSTransition>
  );
};
