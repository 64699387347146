import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const EaIntroductionPage = lazy(() =>
  import('../../pages/app/external-applications/ExternalApplicationsIntroductionPage').then(
    (module) => ({
      default: module['ExternalApplicationsIntroductionPage'],
    })
  )
);
const EaPage = lazy(() =>
  import('../../pages/app/external-applications/ExternalApplicationsPage').then((module) => ({
    default: module['ExternalApplicationsPage'],
  }))
);

/**
 * Contains the route definitions for the external applications module
 */
export const externalApplicactionsRoutes: Route = {
  name: 'External applications',
  path: getLastRoutePart(Routes.AppExternalApplications),
  exact: true,
  routes: [
    {
      name: 'EA introduction',
      path: getLastRoutePart(Routes.AppExternalApplicationsIntroduction),
      exact: true,
      component: EaIntroductionPage,
      routes: [],
    },
    {
      name: 'External applications show',
      path: '/show',
      exact: true,
      routes: [
        {
          name: 'External application',
          path: getLastRoutePart(Routes.AppExternalApplicationsShow),
          exact: true,
          component: EaPage,
          routes: [],
        },
      ],
    },
  ],
};
