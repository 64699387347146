import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faChartLineDown } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MyProjectColors } from '../../../../../styles/colors/MyProjectColors';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const PAS_AUTHORITIES = [
  Authority.PAS_INTRODUCTION_R,
  Authority.PAS_INTRODUCTION_U,
  Authority.PROJECTION_DOCUMENT_C,
  Authority.PROJECTION_DOCUMENT_R,
  Authority.PROJECTION_DOCUMENT_R,
  Authority.PAS_SCENARIO_CHART_R,
  Authority.PAS_SCENARIO_CHART_U,
  Authority.PAS_DOCUMENT_C,
  Authority.PAS_DOCUMENT_R,
  Authority.PAS_DOCUMENT_U,
  Authority.PAS_SOURCES_C,
  Authority.PAS_SOURCES_R,
  Authority.PAS_SOURCES_U,
  Authority.PAS_SOURCES_D,
  Authority.PAS_SHARED_DOCUMENT_C,
  Authority.PAS_SHARED_DOCUMENT_R,
  Authority.PAS_SHARED_DOCUMENT_U,
];

/**
 * Menu items for the PAS module
 */
export const PasMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppPas}
      icon={<FontAwesomeIcon icon={faChartLineDown} />}
      className={MyProjectColors.borderColor}
      text={<FormattedMessage id="menu_pas" defaultMessage="Projections and Scenarios" />}
      authorities={PAS_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppPasIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.PAS_INTRODUCTION_R, Authority.PAS_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPasImport}
        text={<FormattedMessage id="menu_pas_import" defaultMessage="Scenarios import" />}
        authorities={[Authority.PROJECTION_DOCUMENT_C, Authority.PROJECTION_DOCUMENT_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPasScenarios}
        text={<FormattedMessage id="menu_pas_scenarios" defaultMessage="Scenarios data sheet" />}
        authorities={[Authority.PROJECTION_DOCUMENT_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPasScenarioChart}
        text={<FormattedMessage id="menu_pas_scenario_chart" defaultMessage="Scenarios chart" />}
        authorities={[Authority.PAS_SCENARIO_CHART_R, Authority.PAS_SCENARIO_CHART_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPasDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.PAS_DOCUMENT_C, Authority.PAS_DOCUMENT_R, Authority.PAS_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPasSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.PAS_SOURCES_C,
          Authority.PAS_SOURCES_R,
          Authority.PAS_SOURCES_U,
          Authority.PAS_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPasSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.PAS_SHARED_DOCUMENT_C,
          Authority.PAS_SHARED_DOCUMENT_R,
          Authority.PAS_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
