import { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useCurrentUserQuery } from '../../../generated/graphql';
import { setLogoutFinished, setUser } from '../../../state/userState';

/**
 * Handles login events - tries to query the graphql backend for the current user
 * and update the global user state with the current permissions.
 */
export const LoginHandler = () => {
  const dispatch = useDispatch();
  const { data, loading } = useCurrentUserQuery({ fetchPolicy: 'network-only' });
  const currentUser = data?.currentUser;

  useLayoutEffect(() => {
    if (loading) {
      return;
    }

    if (currentUser === null || currentUser === undefined) {
      dispatch(setLogoutFinished());
      return;
    }

    dispatch(setUser({ username: currentUser.username, authorities: currentUser.authorities }));
  }, [currentUser, dispatch, loading]);

  return null;
};
