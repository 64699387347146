import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

/**
 * Displays a copyright with the current year
 * and the name of the owning ministry.
 */
export const MinistryCopyright = () => {
  const currentYear = format(new Date(), 'yyyy');

  return (
    <div>
      &copy; {currentYear}{' '}
      <FormattedMessage
        id="ministry_copyright"
        defaultMessage="Ministry of Environmental Protection"
      />
    </div>
  );
};
