import { useLocalStorage } from 'react-use';
import { defaultTheme } from '../../../styles/themes/defaultTheme';
import { ThemeNames } from '../../../styles/themes/theme';

/**
 * Hook that returns the preferred theme. If the user has already a preferred theme
 * set in the local storage that will used, otherwise the default application theme.
 */
export function usePreferredTheme() {
  const [preferredThemeName] = useLocalStorage<ThemeNames>('dccmrv-theme', defaultTheme.themeName);
  return preferredThemeName ?? defaultTheme.themeName;
}
