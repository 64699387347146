import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  MainMenuItem as OceanMainMenuItem,
  MainMenuItemProps as OceanMainMenuItemProps,
} from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { useHasAnyAuthority } from '../../../../../state/userState';

type MainMenuItemProps = Omit<OceanMainMenuItemProps, 'suffix'> & {
  /**
   * List of authorities that grant access to the given menu item
   */
  authorities: Authority[];
};

/**
 * Customized main menu item with support for font awesome icons.
 */
export const MainMenuItem = (props: MainMenuItemProps) => {
  const { authorities, ...otherProps } = props;
  const canView = useHasAnyAuthority(authorities);

  if (!canView) {
    return null;
  }

  return (
    <OceanMainMenuItem
      {...(otherProps as OceanMainMenuItemProps)}
      suffix={<FontAwesomeIcon icon={faChevronRight} className="ml-5" />}
    />
  );
};
