import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const CcaIntroductionPage = lazy(() =>
  import('../../pages/app/climate-change-adaptation/CcaIntroductionPage').then((module) => ({
    default: module['CcaIntroductionPage'],
  }))
);
const CcaDocumentsPage = lazy(() =>
  import('../../pages/app/climate-change-adaptation/CcaDocumentsPage').then((module) => ({
    default: module['CcaDocumentsPage'],
  }))
);
const CcaSharedDocumentsPage = lazy(() =>
  import('../../pages/app/climate-change-adaptation/CcaSharedDocumentsPage').then((module) => ({
    default: module['CcaSharedDocumentsPage'],
  }))
);
const CcaExternalSourcesPage = lazy(() =>
  import('../../pages/app/climate-change-adaptation/CcaExternalSourcesPage').then((module) => ({
    default: module['CcaExternalSourcesPage'],
  }))
);
const CcaAdaptationsPage = lazy(() =>
  import('../../pages/app/climate-change-adaptation/CcaAdaptationPage').then((module) => ({
    default: module['CcaAdaptationPage'],
  }))
);
const CcaOverviewPage = lazy(() =>
  import('../../pages/app/climate-change-adaptation/CcaOverviewPage').then((module) => ({
    default: module['CcaOverviewPage'],
  }))
);

/**
 * Contains the route definitions for the CCA module administration
 */
export const ccaRoutes: Route = {
  name: 'Climate change adaptation',
  path: getLastRoutePart(Routes.AppCca),
  exact: true,
  routes: [
    {
      name: 'CCA introduction',
      path: getLastRoutePart(Routes.AppCcaIntroduction),
      exact: true,
      component: CcaIntroductionPage,
      routes: [],
    },
    {
      name: 'CCA overview',
      path: getLastRoutePart(Routes.AppCcaOverview),
      exact: true,
      component: CcaOverviewPage,
      routes: [],
    },
    {
      name: 'CCA documents',
      path: getLastRoutePart(Routes.AppCcaDocuments),
      exact: true,
      component: CcaDocumentsPage,
      routes: [],
    },
    {
      name: 'CCA shared documents',
      path: getLastRoutePart(Routes.AppCcaSharedDocuments),
      exact: true,
      component: CcaSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'CCA external sources',
      path: getLastRoutePart(Routes.AppCcaSources),
      exact: true,
      component: CcaExternalSourcesPage,
      routes: [],
    },
    {
      name: 'CCA adaptations',
      path: getLastRoutePart(Routes.AppCcaAdaptations),
      exact: true,
      component: CcaAdaptationsPage,
      routes: [],
    },
  ],
};
