import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkBase, useIntl, useThemeValue } from '@oceanjs/international';
import clsx from 'clsx';
import { faCircleQuestion } from '../../../../lib/fontawesome/pro-light';
import handbookLink from './mrv-user-handbook-1.pdf';

export const HelpIcon = () => {
  const intl = useIntl();
  const hoverClass = useThemeValue('table-button-hover', 'hover:text-public-text');

  return (
    <LinkBase
      className={clsx(
        'focus:outline-none mr-4 text-2xl transition-colors duration-200',
        hoverClass
      )}
      to={handbookLink}
      forceExternal
      // @ts-ignore
      title={intl.formatMessage({ id: 'download_helpdoc', defaultMessage: 'Download user manual' })}
    >
      <FontAwesomeIcon icon={faCircleQuestion} />
    </LinkBase>
  );
};
