import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { ChildrenProps } from '@oceanjs/international';
import { unauthorizedObserver } from './statusObserver';

const httpLink = new HttpLink({ uri: './graphql', credentials: 'include' });
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors?.find((error) => error.message === 'Current user is not logged in')) {
    unauthorizedObserver.notifyUnauthorized();
  }
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: errorLink.concat(httpLink),
});

/**
 * Backend client provider - creates a new apollo client. Wrap this around
 * all of the components that need access to graphql calls. Usually this is
 * done inside the main App.tsx
 */
export const BackendClient = (props: ChildrenProps) => {
  const { children } = props;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
