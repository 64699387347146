import { combineReducers } from '@oceanjs/international';
import { publicMenuReducer } from './publicMenuState';
import { userReducer } from './userState';

/**
 * Root reducer containing the application reducers
 */
export const rootReducer = combineReducers({
  user: userReducer,
  publicMenu: publicMenuReducer,
});

/**
 * Application redux root state
 */
export type RootState = ReturnType<typeof rootReducer>;
