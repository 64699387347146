/**
 * Available application routes
 */
export enum Routes {
  AppHome = '/app',
  AppDashboard = '/app/dashboard',
  AppIntroduction = '/app/introduction',

  AppNdc = '/app/ndc',
  AppNdcIntroduction = '/app/ndc/introduction',
  AppNdcTargetEntry = '/app/ndc/target-entry',
  AppNdcNationalChart = '/app/ndc/national',
  AppNdcSectorChart = '/app/ndc/sectors',
  AppNdcPoliciesAndMeasures = '/app/ndc/policies-and-measures',
  AppNdcAdaptations = '/app/ndc/climate-change-adaptations',
  AppNdcDocuments = '/app/ndc/documents',
  AppNdcSharedDocuments = '/app/ndc/collaboration',
  AppNdcSources = '/app/ndc/sources',

  AppGhg = '/app/ghg',
  AppGhgIntroduction = '/app/ghg/introduction',
  AppGhgImport = '/app/ghg/import',
  AppGhgDatasheet = '/app/ghg/datasheet',
  AppGhgEmissionsGraph = '/app/ghg/emissions-graph',
  AppGhgEmissionsPerSector = '/app/ghg/emissions-per-sector',
  AppGhgEmissionsPerGas = '/app/ghg/emissions-per-gas',
  AppGhgDocuments = '/app/ghg/documents',
  AppGhgDataCollection = '/app/ghg/data-collection',
  AppGhgSharedDocuments = '/app/ghg/collaboration',
  AppGhgSources = '/app/ghg/sources',

  AppPas = '/app/projections-and-scenarios',
  AppPasIntroduction = '/app/projections-and-scenarios/introduction',
  AppPasDocuments = '/app/projections-and-scenarios/documents',
  AppPasSharedDocuments = '/app/projections-and-scenarios/collaboration',
  AppPasSources = '/app/projections-and-scenarios/sources',
  AppPasImport = '/app/projections-and-scenarios/import',
  AppPasScenarios = '/app/projections-and-scenarios/scenarios',
  AppPasScenarioChart = '/app/projections-and-scenarios/scenarios-chart',

  AppPam = '/app/policies-and-measures',
  AppPamIntroduction = '/app/policies-and-measures/introduction',
  AppPamOverview = '/app/policies-and-measures/overview',
  AppPamEntries = '/app/policies-and-measures/entries',
  AppPamDocuments = '/app/policies-and-measures/documents',
  AppPamSharedDocuments = '/app/policies-and-measures/collaboration',
  AppPamSources = '/app/policies-and-measures/sources',

  AppClimateFinance = '/app/climate-finance',
  AppClimateFinanceIntroduction = '/app/climate-finance/introduction',
  AppClimateFinanceOverview = '/app/climate-finance/overview',
  AppClimateFinanceDocuments = '/app/climate-finance/documents',
  AppClimateFinanceSharedDocuments = '/app/climate-finance/collaboration',
  AppClimateFinanceSources = '/app/climate-finance/sources',
  AppClimateFinanceArticle9 = '/app/climate-finance/article-9',
  AppClimateFinanceArticle9Needed = '/app/climate-finance/article-9/needed',
  AppClimateFinanceArticle9Received = '/app/climate-finance/article-9/received',
  AppClimateFinanceArticle10 = '/app/climate-finance/article-10',
  AppClimateFinanceArticle10Needed = '/app/climate-finance/article-10/needed',
  AppClimateFinanceArticle10Received = '/app/climate-finance/article-10/received',
  AppClimateFinanceArticle11 = '/app/climate-finance/article-11',
  AppClimateFinanceArticle11Needed = '/app/climate-finance/article-11/needed',
  AppClimateFinanceArticle11Received = '/app/climate-finance/article-11/received',
  AppClimateFinanceArticle13 = '/app/climate-finance/article-13',
  AppClimateFinanceArticle13Needed = '/app/climate-finance/article-13/needed-received',

  AppCca = '/app/climate-change-adaptation',
  AppCcaIntroduction = '/app/climate-change-adaptation/introduction',
  AppCcaOverview = '/app/climate-change-adaptation/overview',
  AppCcaDocuments = '/app/climate-change-adaptation/documents',
  AppCcaSharedDocuments = '/app/climate-change-adaptation/collaboration',
  AppCcaSources = '/app/climate-change-adaptation/sources',
  AppCcaAdaptations = '/app/climate-change-adaptation/adaptations',

  AppNc = '/app/nc',
  AppNcPoliciesAndMeasures = '/app/nc/policies-and-measures',
  AppNcAdaptations = '/app/nc/climate-change-adaptations',
  AppNcDocuments = '/app/nc/documents',
  AppNcSharedDocuments = '/app/nc/collaboration',
  AppNcSources = '/app/nc/sources',

  AppIndi = '/app/indicators',
  AppIndiIntroduction = '/app/indicators/introduction',
  AppIndiGhg = '/app/indicators/ghg',
  AppIndiGhgOverview = '/app/indicators/ghg/overview',
  AppIndiGhgPrio1 = '/app/indicators/ghg/1-priority',
  AppIndiGhgAprio = '/app/indicators/ghg/additional-priority',
  AppIndiGhgSuppl = '/app/indicators/ghg/supplementary',
  AppIndiOther = '/app/indicators/other',
  AppIndiOtherOverview = '/app/indicators/other/overview',
  AppIndiOtherData = '/app/indicators/other/data-entry',
  AppIndiCca = '/app/indicators/climate-change-adaptation',
  AppIndiCcaOverview = '/app/indicators/climate-change-adaptation/overview',
  AppIndiCcaData = '/app/indicators/climate-change-adaptation/data-entry',
  AppIndiPas = '/app/indicators/policies-and-measures',
  AppIndiPasOverview = '/app/indicators/policies-and-measures/overview',
  AppIndiPasData = '/app/indicators/policies-and-measures/data-entry',
  AppIndiDocuments = '/app/indicators/documents',
  AppIndiSharedDocuments = '/app/indicators/collaboration',
  AppIndiSources = '/app/indicators/sources',

  AppExternalApplications = '/app/external-applications',
  AppExternalApplicationsIntroduction = '/app/external-applications/introduction',
  AppExternalApplicationsShow = '/app/external-applications/show/:id',

  AppAdministration = '/app/administration',
  AppUserAdministration = '/app/administration/users',
  AppOrganizationAdministration = '/app/administration/organizations',
  AppModuleVisibilityAdministration = '/app/administration/module-visibility',
  AppDataCollectionAdministration = '/app/administration/data-collection-templates',
  AppExternalApplicationsAdministration = '/app/administration/external-applications',
  AppLogout = '/app/logout',

  Home = '/home',
  Login = '/login',
  UpdatePassword = '/update-password',
  ForgotPassword = '/forgot-password',
  PasswordToken = '/password-token',
}

/**
 * Returns the last directory part of the route, useful for
 * defining @oceanjs routes
 *
 * @param route Route
 * @returns Last part of the route
 */
export function getLastRoutePart(route: Routes) {
  const lastIndexOfDash = route.lastIndexOf('/');
  if (lastIndexOfDash <= 0) {
    return route;
  }

  return route.substr(lastIndexOfDash);
}
