import { ButtonBase } from '@oceanjs/core';
import { useSetMainMenuVisible } from '@oceanjs/international';
import clsx from 'clsx';
import { useUsername } from '../../../../state/userState';
import { OptionalClassNameProps } from '../../../../typings/classNameProps';

/**
 * Displays a user name avatar which also acts as a button to show and hide the main menu.
 */
export const UserInfo = (props: OptionalClassNameProps) => {
  const { className } = props;

  const showMainMenu = useSetMainMenuVisible();
  const username = useUsername();

  return (
    <ButtonBase
      onClick={() => showMainMenu(true)}
      className={clsx(className, 'rounded-full bg-white text-gray-900 p-2')}
    >
      {username}
    </ButtonBase>
  );
};
