import { Theme } from './theme';

/**
 * Contains keys for the light theme version of this application
 */
export const lightTheme: Theme = {
  'aside-background': 'bg-appBackground',
  'aside-text': 'text-white',
  'body-background': 'bg-gray-300',
  'content-background': 'bg-white',
  link: 'text-teal-700 hover:text-blue-600 focus:text-blue-600',
  text: 'text-black',
  'card-background': 'bg-gray-200',
  'card-text': 'text-black',
  'heading-text': 'text-light-textHighlight',
  'divider-color': 'border-gray-200',
  'divide-color': 'divide-blue-800',
  'main-menu-width': 'w-publicMenu',
  'main-menu-item-hover': 'focus:bg-gray-100 hover:bg-gray-100',
  'label-text': 'text-gray-700',
  'table-border': 'border-gray-300',
  'table-header-text': 'text-teal-900',
  'table-row-hover': 'hover:bg-gray-200',
  'table-row-edit': 'bg-gray-200',
  'table-button-hover': 'hover:text-teal-700',
  'info-background': 'bg-yellow-300',
  'active-pill': 'bg-teal-700 text-white',
  'inactive-pill': 'hover:bg-gray-200',
  'secondary-text': 'text-gray-600',
  'drop-border': 'border-appBackground',
  'secondary-heading-text': 'text-appBackground',
  'language-link': 'text-gray-800 hover:text-blue-600',
  'aside-link': 'text-public-textHighlight hover:text-dark-linkHover focus:text-dark-linkHover',
  'messagebar-info': 'border-blue-400 bg-blue-300',
  'messagebar-error': 'border-red-800 bg-red-700 text-white',
  charttheme: 'light',
  'charttheme-internal': 'lightInternal',
  'landing-menu': 'bg-public-landingMenuBackground',

  themeName: 'light',
};
