import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faFlag } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { NdcColors } from '../../../../../styles/colors/NdcColors';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const NDC_AUTHORITIES = [
  Authority.NDC_INTRODUCTION_R,
  Authority.NDC_INTRODUCTION_U,
  Authority.NDC_TARGET_R,
  Authority.NDC_TARGET_U,
  Authority.NDC_NATIONAL_CHART_R,
  Authority.NDC_NATIONAL_CHART_U,
  Authority.NDC_SECTOR_CHART_R,
  Authority.NDC_SECTOR_CHART_U,
  Authority.NDC_DOCUMENT_C,
  Authority.NDC_DOCUMENT_R,
  Authority.NDC_DOCUMENT_U,
  Authority.NDC_SOURCES_C,
  Authority.NDC_SOURCES_R,
  Authority.NDC_SOURCES_U,
  Authority.NDC_SOURCES_D,
  Authority.NDC_SHARED_DOCUMENT_C,
  Authority.NDC_SHARED_DOCUMENT_R,
  Authority.NDC_SHARED_DOCUMENT_U,
  Authority.NDC_RELEVANT_CCA_R,
  Authority.NDC_RELEVANT_PAM_R,
];

/**
 * Menu items for the NDC module
 */
export const NdcMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppNdc}
      icon={<FontAwesomeIcon icon={faFlag} />}
      className={NdcColors.borderColor}
      text={<FormattedMessage id="menu_ndc" defaultMessage="Nationally Determined Contributions" />}
      authorities={NDC_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppNdcIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.NDC_INTRODUCTION_R, Authority.NDC_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcTargetEntry}
        text={<FormattedMessage id="menu_ndc_target_entry" defaultMessage="NDC target entry" />}
        authorities={[Authority.NDC_TARGET_R, Authority.NDC_TARGET_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcNationalChart}
        text={
          <FormattedMessage
            id="menu_ndc_national_chart"
            defaultMessage="National GHG emissions trend"
          />
        }
        authorities={[Authority.NDC_NATIONAL_CHART_R, Authority.NDC_NATIONAL_CHART_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcSectorChart}
        text={
          <FormattedMessage
            id="menu_ndc_sector_chart"
            defaultMessage="GHG emissions trend by sector"
          />
        }
        authorities={[Authority.NDC_SECTOR_CHART_R, Authority.NDC_SECTOR_CHART_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcPoliciesAndMeasures}
        text={
          <FormattedMessage
            id="menu_ndc_pams"
            defaultMessage="NDC relevant policies and measures"
          />
        }
        authorities={[Authority.NDC_RELEVANT_PAM_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcAdaptations}
        text={
          <FormattedMessage
            id="menu_ndc_adaptations"
            defaultMessage="NDC relevant climate change adaptations"
          />
        }
        authorities={[Authority.NDC_RELEVANT_CCA_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.NDC_DOCUMENT_C, Authority.NDC_DOCUMENT_R, Authority.NDC_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.NDC_SOURCES_C,
          Authority.NDC_SOURCES_R,
          Authority.NDC_SOURCES_U,
          Authority.NDC_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNdcSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.NDC_SHARED_DOCUMENT_C,
          Authority.NDC_SHARED_DOCUMENT_R,
          Authority.NDC_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
