import { ButtonBase, ChildrenProps } from '@oceanjs/international';
import clsx from 'clsx';
import { ClassNameProps } from '../../../typings/classNameProps';

interface FooterMenuItemProps extends ChildrenProps, ClassNameProps {
  /**
   * onClick callback
   */
  onClick?(): void;
}

/**
 * Menu item to be used inside the footer navigation.
 * Will render a button with an attached onlcick handler
 */
export const FooterMenuItem = (props: FooterMenuItemProps) => {
  const { onClick, children, className } = props;

  return (
    <ButtonBase onClick={onClick} className={clsx('text-left focus:outline-none', className)}>
      {children}
    </ButtonBase>
  );
};
