import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const UserAdministrationPage = lazy(() =>
  import('../../pages/app/administration/UserAdministrationPage').then((module) => ({
    default: module['UserAdministrationPage'],
  }))
);
const OrganizationAdministrationPage = lazy(() =>
  import('../../pages/app/administration/OrganizationAdministrationPage').then((module) => ({
    default: module['OrganizationAdministrationPage'],
  }))
);
const ModuleVisibilityAdministrationPage = lazy(() =>
  import('../../pages/app/administration/ModuleVisibilityAdministrationPage').then((module) => ({
    default: module['ModuleVisibilityAdministrationPage'],
  }))
);
const DataCollectionAdministrationPage = lazy(() =>
  import('../../pages/app/administration/DataCollectionAdministrationPage').then((module) => ({
    default: module['DataCollectionAdministrationPage'],
  }))
);
const ExternalApplicationsAdministrationPage = lazy(() =>
  import('../../pages/app/administration/ExternalApplicationsAdministrationPage').then(
    (module) => ({
      default: module['ExternalApplicationsAdministrationPage'],
    })
  )
);

/**
 * Contains the route definitions for the Administration module
 */
export const administrationRoutes: Route = {
  name: 'Administration',
  path: getLastRoutePart(Routes.AppAdministration),
  exact: true,
  routes: [
    {
      name: 'User administration',
      path: getLastRoutePart(Routes.AppUserAdministration),
      exact: true,
      component: UserAdministrationPage,
      routes: [],
    },
    {
      name: 'Organization administration',
      path: getLastRoutePart(Routes.AppOrganizationAdministration),
      exact: true,
      component: OrganizationAdministrationPage,
      routes: [],
    },
    {
      name: 'Module visibility administration',
      path: getLastRoutePart(Routes.AppModuleVisibilityAdministration),
      exact: true,
      component: ModuleVisibilityAdministrationPage,
      routes: [],
    },
    {
      name: 'Data collection templates',
      path: getLastRoutePart(Routes.AppDataCollectionAdministration),
      exact: true,
      component: DataCollectionAdministrationPage,
      routes: [],
    },
    {
      name: 'External applications',
      path: getLastRoutePart(Routes.AppExternalApplicationsAdministration),
      exact: true,
      component: ExternalApplicationsAdministrationPage,
      routes: [],
    },
  ],
};
