import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonBase,
  Flex,
  useIntl,
  useLocalStorage,
  useSwitchTheme,
  useThemeName,
} from '@oceanjs/international';
import { useCallback } from 'react';
import { faMoon, faSun, faToggleOff, faToggleOn } from '../../lib/fontawesome/pro-light';
import { darkTheme } from '../../styles/themes/darkTheme';
import { lightTheme } from '../../styles/themes/lightTheme';

/**
 * Component displaying a switch button to switch themes.
 *
 * Additionally the theme selection will be persisted inside
 * the local storage under the *dccmrv-theme* key.
 */
export const ThemeSwitcher = () => {
  const intl = useIntl();
  const currentTheme = useThemeName() ?? 'light';
  const switchTheme = useSwitchTheme();

  const isLightMode = currentTheme === 'light';
  const [, persistThemeSelection] = useLocalStorage('dccmrv-theme', 'light');

  const toggleTheme = useCallback(() => {
    const newTheme = isLightMode ? darkTheme : lightTheme;

    switchTheme(newTheme.themeName, newTheme);
    persistThemeSelection(newTheme.themeName);
  }, [isLightMode, persistThemeSelection, switchTheme]);

  const toggleIcon = isLightMode ? faToggleOff : faToggleOn;
  const themeIcon = isLightMode ? faMoon : faSun;
  const title = isLightMode
    ? intl.formatMessage({ id: 'switch_dark', defaultMessage: 'Switch to dark theme' })
    : intl.formatMessage({ id: 'switch_light', defaultMessage: 'Switch to light theme' });

  return (
    <ButtonBase className="focus:outline-none" onClick={toggleTheme} title={title}>
      <Flex className="text-2xl space-x-2">
        <FontAwesomeIcon icon={themeIcon} />
        <FontAwesomeIcon icon={toggleIcon} />
      </Flex>
    </ButtonBase>
  );
};
