import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const NdcIntroductionPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcIntroductionPage').then(
    (module) => ({
      default: module['NdcIntroductionPage'],
    })
  )
);
const NdcDocumentsPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcDocumentsPage').then((module) => ({
    default: module['NdcDocumentsPage'],
  }))
);
const NdcExternalSourcesPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcExternalSourcesPage').then(
    (module) => ({
      default: module['NdcExternalSourcesPage'],
    })
  )
);
const NdcTargetEntryPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcTargetEntryPage').then(
    (module) => ({
      default: module['NdcTargetEntryPage'],
    })
  )
);
const NdcNationalChartPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcNationalChartPage').then(
    (module) => ({
      default: module['NdcNationalChartPage'],
    })
  )
);
const NdcSectorChartPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcSectorChartPage').then(
    (module) => ({
      default: module['NdcSectorChartPage'],
    })
  )
);
const NdcSharedDocumentsPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcSharedDocumentsPage').then(
    (module) => ({
      default: module['NdcSharedDocumentsPage'],
    })
  )
);
const NdcRelevantPamsPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcRelevantPamsPage').then(
    (module) => ({
      default: module['NdcRelevantPamsPage'],
    })
  )
);
const NdcRelevantAdaptationsPage = lazy(() =>
  import('../../pages/app/nationally-determined-contributions/NdcRelevantAdaptationsPage').then(
    (module) => ({
      default: module['NdcRelevantAdaptationsPage'],
    })
  )
);

/**
 * Contains the route definitions for the NDC module administration
 */
export const ndcRoutes: Route = {
  name: 'NDC',
  path: getLastRoutePart(Routes.AppNdc),
  exact: true,
  routes: [
    {
      name: 'NDC introduction',
      path: getLastRoutePart(Routes.AppNdcIntroduction),
      exact: true,
      component: NdcIntroductionPage,
      routes: [],
    },
    {
      name: 'NDC target entry',
      path: getLastRoutePart(Routes.AppNdcTargetEntry),
      exact: true,
      component: NdcTargetEntryPage,
      routes: [],
    },
    {
      name: 'NDC national chart',
      path: getLastRoutePart(Routes.AppNdcNationalChart),
      exact: true,
      component: NdcNationalChartPage,
      routes: [],
    },
    {
      name: 'NDC sector chart',
      path: getLastRoutePart(Routes.AppNdcSectorChart),
      exact: true,
      component: NdcSectorChartPage,
      routes: [],
    },
    {
      name: 'NDC policies and measures',
      path: getLastRoutePart(Routes.AppNdcPoliciesAndMeasures),
      exact: true,
      component: NdcRelevantPamsPage,
      routes: [],
    },
    {
      name: 'NDC adaptations',
      path: getLastRoutePart(Routes.AppNdcAdaptations),
      exact: true,
      component: NdcRelevantAdaptationsPage,
      routes: [],
    },
    {
      name: 'NDC documents',
      path: getLastRoutePart(Routes.AppNdcDocuments),
      exact: true,
      component: NdcDocumentsPage,
      routes: [],
    },
    {
      name: 'NDC shared documents',
      path: getLastRoutePart(Routes.AppNdcSharedDocuments),
      exact: true,
      component: NdcSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'NDC external sources',
      path: getLastRoutePart(Routes.AppNdcSources),
      exact: true,
      component: NdcExternalSourcesPage,
      routes: [],
    },
  ],
};
