import { ChildrenProps, useSwitchTheme, useThemeName } from '@oceanjs/international';
import { useLayoutEffect } from 'react';
import { themes } from '../../styles/themes/themes';
import { usePreferredTheme } from './hooks/usePreferredTheme';

/**
 * Wrapper for setting the default theme.
 *
 * Per default we can't inject a default theme into the redux store because the
 * theme reducer is definied in oceanjs. This component will check for a theme
 * inside the store and set the default one if none was found.
 */
export const DefaultThemeProvider = (props: ChildrenProps) => {
  const { children } = props;

  const preferredThemeName = usePreferredTheme();
  const currentTheme = useThemeName();
  const switchTheme = useSwitchTheme();
  useLayoutEffect(() => {
    if (currentTheme !== undefined) {
      return;
    }

    switchTheme(preferredThemeName, themes[preferredThemeName]);
  }, [currentTheme, preferredThemeName, switchTheme]);

  if (currentTheme === undefined) {
    return null;
  }

  return <>{children}</>;
};
