import { ChildrenProps } from '@oceanjs/international';
import { Suspense } from 'react';
import { InternalLayout } from '../../components/InternalLayout';
import { LoadingSpinner } from '../../components/LoadingSpinner';

/**
 * Wrapper for internal routes, will add the internal layout containing
 * the main menu, the header and the footer around the child route
 */
export const InternalWrapper = (props: ChildrenProps) => {
  const { children } = props;

  return (
    <InternalLayout>
      <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
    </InternalLayout>
  );
};
