import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, MainMenu as OceanMainMenu } from '@oceanjs/international';
import { Authority } from '../../../../Authority';
import {
  faArrowRightFromBracket,
  faHouse,
  faMessageText,
} from '../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../routes/Routes';
import { AdministrationMenuItems } from './components/AdministrationMenuItems';
import { CcaMenuItems } from './components/CcaMenuItems';
import { CfMenuItems } from './components/CfMenuItems';
import { ExternalApplicationsMenuItems } from './components/ExternalApplicationsMenuItems';
import { GhgMenuItems } from './components/GhgMenuItems';
import { IndicatorMenuitems } from './components/IndicatorMenuitems';
import { MainMenuItem } from './components/MainMenuItem';
import { NcMenuItems } from './components/NcMenuItems';
import { NdcMenuItems } from './components/NdcMenuItems';
import { PamMenuItems } from './components/PamMenuItems';
import { PasMenuItems } from './components/PasMenuItems';

/**
 * Main menu for the internal layout
 */
export const MainMenu = () => {
  return (
    <OceanMainMenu>
      <MainMenuItem
        to={Routes.AppHome}
        icon={<FontAwesomeIcon icon={faHouse} />}
        className="border-appBackground"
        exact
        text={<FormattedMessage id="main_menu_home" defaultMessage="Home" />}
        authorities={[]}
      />
      <MainMenuItem
        to={Routes.AppIntroduction}
        icon={<FontAwesomeIcon icon={faMessageText} />}
        className="border-red-900"
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.INTRODUCTION_U]}
      />
      <NdcMenuItems />
      <GhgMenuItems />
      <PasMenuItems />
      <PamMenuItems />
      <CfMenuItems />
      <CcaMenuItems />
      <NcMenuItems />
      <IndicatorMenuitems />
      <ExternalApplicationsMenuItems />
      <AdministrationMenuItems />
      <MainMenuItem
        to={Routes.AppLogout}
        icon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
        className="border-gray-900"
        text={<FormattedMessage id="main_menu_logout" defaultMessage="Logout" />}
        authorities={[]}
      />
    </OceanMainMenu>
  );
};
