import clsx from 'clsx';
import { useAsideClasses } from '../../styles/hooks/asideHooks';
import { OptionalClassNameProps } from '../../typings/classNameProps';
import { FooterNavigation } from './components/FooterNavigation';
import { MinistryCopyright } from './components/MinistryCopyright';

/**
 * Main application footer, renders the following things:
 *
 * - Copyright with the ministry name
 * - Links to switch the current language
 */
export const Footer = (props: OptionalClassNameProps) => {
  const { className } = props;

  const asideClasses = useAsideClasses();

  return (
    <footer className={clsx('mb-4 md:h-20', className)}>
      <div
        className={clsx(
          'shadow-md flex flex-col md:flex-row md:items-center items-start justify-between md:h-20 p-4 space-x-4',
          asideClasses
        )}
      >
        <MinistryCopyright />
        <FooterNavigation />
      </div>
    </footer>
  );
};
