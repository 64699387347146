import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faScrewdriverWrench } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const ADMINISTRATION_AUTHORITIES = [
  Authority.USER_ADMINISTRATION_C,
  Authority.USER_ADMINISTRATION_D,
  Authority.USER_ADMINISTRATION_R,
  Authority.USER_ADMINISTRATION_U,
  Authority.ORGANIZATION_ADMINISTRATION_C,
  Authority.ORGANIZATION_ADMINISTRATION_D,
  Authority.ORGANIZATION_ADMINISTRATION_R,
  Authority.ORGANIZATION_ADMINISTRATION_U,
  Authority.MODULE_VISIBILITY_ADMINISTRATION_C,
  Authority.MODULE_VISIBILITY_ADMINISTRATION_R,
  Authority.MODULE_VISIBILITY_ADMINISTRATION_U,
  Authority.MODULE_VISIBILITY_ADMINISTRATION_D,
  Authority.DATA_COLLECTION_TEMPLATES_C,
  Authority.DATA_COLLECTION_TEMPLATES_R,
  Authority.DATA_COLLECTION_TEMPLATES_U,
  Authority.DATA_COLLECTION_TEMPLATES_D,
  Authority.EXTERNAL_APPLICATION_C,
  Authority.EXTERNAL_APPLICATION_R,
  Authority.EXTERNAL_APPLICATION_U,
  Authority.EXTERNAL_APPLICATION_D,
];

/**
 * Menu items for the Administration module
 */
export const AdministrationMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppAdministration}
      icon={<FontAwesomeIcon icon={faScrewdriverWrench} />}
      className="border-gray-700"
      text={<FormattedMessage id="main_menu_administration" defaultMessage="Administration" />}
      authorities={ADMINISTRATION_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppUserAdministration}
        text={<FormattedMessage id="menu_user_administration" defaultMessage="Users" />}
        authorities={[
          Authority.USER_ADMINISTRATION_C,
          Authority.USER_ADMINISTRATION_D,
          Authority.USER_ADMINISTRATION_R,
          Authority.USER_ADMINISTRATION_U,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppOrganizationAdministration}
        text={
          <FormattedMessage id="menu_organization_administration" defaultMessage="Organizations" />
        }
        authorities={[
          Authority.ORGANIZATION_ADMINISTRATION_C,
          Authority.ORGANIZATION_ADMINISTRATION_D,
          Authority.ORGANIZATION_ADMINISTRATION_R,
          Authority.ORGANIZATION_ADMINISTRATION_U,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppModuleVisibilityAdministration}
        text={
          <FormattedMessage
            id="menu_module_visibility"
            defaultMessage="Public visibility of modules"
          />
        }
        authorities={[
          Authority.MODULE_VISIBILITY_ADMINISTRATION_C,
          Authority.MODULE_VISIBILITY_ADMINISTRATION_R,
          Authority.MODULE_VISIBILITY_ADMINISTRATION_U,
          Authority.MODULE_VISIBILITY_ADMINISTRATION_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppDataCollectionAdministration}
        text={
          <FormattedMessage
            id="menu_data_collection_templates"
            defaultMessage="GHG data collection workspaces"
          />
        }
        authorities={[
          Authority.DATA_COLLECTION_TEMPLATES_C,
          Authority.DATA_COLLECTION_TEMPLATES_R,
          Authority.DATA_COLLECTION_TEMPLATES_U,
          Authority.DATA_COLLECTION_TEMPLATES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppExternalApplicationsAdministration}
        text={
          <FormattedMessage
            id="menu_external_applications"
            defaultMessage="External applications"
          />
        }
        authorities={[
          Authority.EXTERNAL_APPLICATION_C,
          Authority.EXTERNAL_APPLICATION_R,
          Authority.EXTERNAL_APPLICATION_U,
          Authority.EXTERNAL_APPLICATION_D,
        ]}
      />
    </MainMenuItem>
  );
};
