import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faComments } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const NC_AUTHORITIES = [
  Authority.NC_DOCUMENT_C,
  Authority.NC_DOCUMENT_R,
  Authority.NC_DOCUMENT_U,
  Authority.NC_SOURCES_C,
  Authority.NC_SOURCES_R,
  Authority.NC_SOURCES_U,
  Authority.NC_SOURCES_D,
  Authority.NC_SHARED_DOCUMENT_C,
  Authority.NC_SHARED_DOCUMENT_R,
  Authority.NC_SHARED_DOCUMENT_U,
  Authority.NC_RELEVANT_CCA_R,
  Authority.NC_RELEVANT_PAM_R,
];

/**
 * Menu items for the NC module
 */
export const NcMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppNc}
      icon={<FontAwesomeIcon icon={faComments} />}
      className="border-pink-400"
      text={<FormattedMessage id="menu_nc" defaultMessage="National Reporting" />}
      authorities={NC_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppNcPoliciesAndMeasures}
        text={
          <FormattedMessage id="menu_nc_pams" defaultMessage="NR relevant policies and measures" />
        }
        authorities={[Authority.NC_RELEVANT_PAM_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNcAdaptations}
        text={
          <FormattedMessage
            id="menu_nc_adaptations"
            defaultMessage="NR relevant climate change adaptations"
          />
        }
        authorities={[Authority.NC_RELEVANT_CCA_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNcDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.NC_DOCUMENT_C, Authority.NC_DOCUMENT_R, Authority.NC_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNcSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.NC_SOURCES_C,
          Authority.NC_SOURCES_R,
          Authority.NC_SOURCES_U,
          Authority.NC_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppNcSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.NC_SHARED_DOCUMENT_C,
          Authority.NC_SHARED_DOCUMENT_R,
          Authority.NC_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
