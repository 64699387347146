import { StrictMode } from 'react';
import { render } from 'react-dom';
import { setAppElement } from 'react-modal';
import { App } from './components/App';
import './css/main.css';

setAppElement('#root');

render(
  <StrictMode>
    <App />
  </StrictMode>,
  document.getElementById('root')
);
