import { useCurrentLanguage, useSwitchCurrentLanguage } from '@oceanjs/international';
import clsx from 'clsx';
import { languageDisplayName, supportedLanguages } from '../../../i18n/supportedLanguages';
import { FooterMenuItem } from './FooterMenuItem';

/**
 * Displays the main footer navigation with links
 * to switch to the supported application languages.
 */
export const FooterNavigation = () => {
  const currentLanguage = useCurrentLanguage();
  const switchLanguage = useSwitchCurrentLanguage();

  return (
    <nav className="flex flex-col md:flex-row md:space-x-5">
      {supportedLanguages.map((language) => (
        <FooterMenuItem
          key={language}
          onClick={() => switchLanguage(language)}
          className={clsx({ 'text-public-textHighlight': language === currentLanguage })}
        >
          {languageDisplayName[language]}
        </FooterMenuItem>
      ))}
    </nav>
  );
};
