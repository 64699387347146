import { useThemeValue } from '@oceanjs/international';
import clsx from 'clsx';
import { darkTheme } from '../themes/darkTheme';

/**
 * Returns a class name used for styling the background of aside elements
 */
export function useAsideBackgroundClass() {
  return useThemeValue('aside-background', darkTheme['aside-background']);
}

/**
 * Returns a class name used for styling the text color of aside elements
 */
export function useAsideTextClass() {
  return useThemeValue('aside-text', darkTheme['aside-text']);
}

/**
 * Returns all classes that should be applied to aside elements
 */
export function useAsideClasses() {
  const background = useAsideBackgroundClass();
  const text = useAsideTextClass();

  return clsx(background, text);
}
