import {
  ChildrenProps,
  ContentContainer,
  DateTimeClock,
  Flex,
  HeaderBar,
} from '@oceanjs/international';
import { Suspense } from 'react';
import { Redirect } from 'react-router';
import { Routes } from '../../routes/Routes';
import { useIsLoggedIn } from '../../state/userState';
import { Footer } from '../Footer';
import { LoadingSpinner } from '../LoadingSpinner';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { HelpIcon } from './components/HelpIcon';
import { MainMenu } from './components/MainMenu';
import { UserInfo } from './components/UserInfo';

/**
 * Renders the internal layout around its children. Contains the header,
 * the main menu and the footer components.
 *
 * Will redirect to the public page if the current user is not logged in.
 */
export const InternalLayout = (props: ChildrenProps) => {
  const { children } = props;

  const loggedIn = useIsLoggedIn();
  if (!loggedIn) {
    return <Redirect to={Routes.Home} />;
  }

  return (
    <>
      <HeaderBar>
        <Flex alignItems="center">
          <UserInfo className="mr-2" />
          <DateTimeClock />
        </Flex>
        <Flex>
          <HelpIcon />
          <ThemeSwitcher />
        </Flex>
      </HeaderBar>

      <ContentContainer footer={<Footer className="hidden lg:block" />}>
        <MainMenu />
        <Suspense fallback={<LoadingSpinner />}>{children}</Suspense>
      </ContentContainer>
    </>
  );
};
