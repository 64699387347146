import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { InternalWrapper } from '../wrappers/InternalWrapper';
import { administrationRoutes } from './internal/administrationRoutes';
import { ccaRoutes } from './internal/ccaRoutes';
import { cfRoutes } from './internal/cfRoutes';
import { externalApplicactionsRoutes } from './internal/externalApplicationsRoutes';
import { ghgRoutes } from './internal/ghgRoutes';
import { indiRoutes } from './internal/indiRoutes';
import { ncRoutes } from './internal/ncRoutes';
import { ndcRoutes } from './internal/ndcRoutes';
import { pamRoutes } from './internal/pamRoutes';
import { pasRoutes } from './internal/pasRoutes';
import { getLastRoutePart, Routes } from './Routes';

const DashboardPage = lazy(() =>
  import('../pages/app/DashboardPage').then((module) => ({ default: module['DashboardPage'] }))
);
const LogoutPage = lazy(() =>
  import('../pages/app/LogoutPage').then((module) => ({
    default: module['LogoutPage'],
  }))
);
const IntroductionPage = lazy(() =>
  import('../pages/app/IntroductionPage').then((module) => ({
    default: module['IntroductionPage'],
  }))
);

/**
 * Route definitions for the internal routes
 */
export const internalRoutes: Route = {
  name: 'App',
  path: getLastRoutePart(Routes.AppHome),
  wrapperComponent: InternalWrapper,
  routes: [
    {
      name: 'Dashboard',
      path: getLastRoutePart(Routes.AppDashboard),
      exact: true,
      component: DashboardPage,
      routes: [],
    },
    {
      name: 'Logout',
      path: getLastRoutePart(Routes.AppLogout),
      exact: true,
      component: LogoutPage,
      routes: [],
    },
    {
      name: 'Introduction',
      path: getLastRoutePart(Routes.AppIntroduction),
      exact: true,
      component: IntroductionPage,
      routes: [],
    },
    ndcRoutes,
    ghgRoutes,
    cfRoutes,
    pasRoutes,
    ccaRoutes,
    pamRoutes,
    ncRoutes,
    indiRoutes,
    externalApplicactionsRoutes,
    administrationRoutes,
  ],
};
