import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const NcDocumentsPage = lazy(() =>
  import('../../pages/app/national-communication/NcDocumentsPage').then((module) => ({
    default: module['NcDocumentsPage'],
  }))
);
const NcExternalSourcesPage = lazy(() =>
  import('../../pages/app/national-communication/NcExternalSourcesPage').then((module) => ({
    default: module['NcExternalSourcesPage'],
  }))
);
const NcSharedDocumentsPage = lazy(() =>
  import('../../pages/app/national-communication/NcSharedDocumentsPage').then((module) => ({
    default: module['NcSharedDocumentsPage'],
  }))
);
const NcRelevantPamsPage = lazy(() =>
  import('../../pages/app/national-communication/NcRelevantPamsPage').then((module) => ({
    default: module['NcRelevantPamsPage'],
  }))
);
const NcRelevantAdaptationsPage = lazy(() =>
  import('../../pages/app/national-communication/NcRelevantAdaptationsPage').then((module) => ({
    default: module['NcRelevantAdaptationsPage'],
  }))
);

/**
 * Contains the route definitions for the NC module administration
 */
export const ncRoutes: Route = {
  name: 'NC',
  path: getLastRoutePart(Routes.AppNc),
  exact: true,
  routes: [
    {
      name: 'NC policies and measures',
      path: getLastRoutePart(Routes.AppNcPoliciesAndMeasures),
      exact: true,
      component: NcRelevantPamsPage,
      routes: [],
    },
    {
      name: 'NC adaptations',
      path: getLastRoutePart(Routes.AppNcAdaptations),
      exact: true,
      component: NcRelevantAdaptationsPage,
      routes: [],
    },
    {
      name: 'NC documents',
      path: getLastRoutePart(Routes.AppNcDocuments),
      exact: true,
      component: NcDocumentsPage,
      routes: [],
    },
    {
      name: 'NC shared documents',
      path: getLastRoutePart(Routes.AppNcSharedDocuments),
      exact: true,
      component: NcSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'NC external sources',
      path: getLastRoutePart(Routes.AppNcSources),
      exact: true,
      component: NcExternalSourcesPage,
      routes: [],
    },
  ],
};
