import { createSlice, PayloadAction, useSelector } from '@oceanjs/international';
import { Authority } from '../Authority';
import { RootState } from './reducer';

interface UserState {
  userstate: 'logged-out' | 'logging-in' | 'logged-in' | 'logging-out';
  username: string | undefined;
  authorities: string[];
}

const initialState: UserState = {
  userstate: 'logging-in',
  username: undefined,
  authorities: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setLogin: (state, _action: PayloadAction) => {
      state.userstate = 'logging-in';
    },
    setLogout: (state, _action: PayloadAction) => {
      state.userstate = 'logging-out';
    },
    setLogoutFinished: (state, _action: PayloadAction) => {
      state.userstate = 'logged-out';
      state.username = undefined;
      state.authorities = [];
    },
    setUser: (state, action: PayloadAction<Pick<UserState, 'username' | 'authorities'>>) => {
      state.userstate = 'logged-in';
      state.username = action.payload.username;
      state.authorities = action.payload.authorities;
    },
  },
});

/**
 * User state actions
 */
export const { setLogin, setLogout, setLogoutFinished, setUser } = userSlice.actions;
/**
 * User state reducer
 */
export const userReducer = userSlice.reducer;

/**
 * Returns true if the user is currently logged in
 * @returns True if the user is logged in, otherwise false
 */
export function useIsLoggedIn() {
  return useSelector((state: RootState) => state.user.userstate === 'logged-in');
}

/**
 * Returns the current user state.
 *
 * **Use this only if you know what you're doing.**
 * @see useIsLoggedIn
 * @returns Current user state
 */
export function useUserState() {
  return useSelector((state: RootState) => state.user.userstate);
}

/**
 * Returns the current user name or undefined if not logged in.
 * @returns Username or undefined
 */
export function useUsername() {
  return useSelector((state: RootState) => state.user.username);
}

/**
 * Returns true if the user has the given authority.
 * @param authority Authority to check
 * @returns True if the user has the authority
 */
export function useHasAuthority(authority: Authority) {
  return useSelector((state: RootState) => state.user.authorities.includes(authority));
}

/**
 * Returns true if the user has any authority from the given list.
 * @param authorities List of authorities to check
 * @returns True if the user has any authority from the list
 */
export function useHasAnyAuthority(authorities: Authority[]) {
  return useSelector(
    (state: RootState) =>
      authorities.length === 0 ||
      authorities.some((authority) => state.user.authorities.includes(authority))
  );
}
