import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from '@oceanjs/international';
import { Authority } from '../../../../../Authority';
import { faBookSection } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { ProjectAdministrationColors } from '../../../../../styles/colors/ProjectAdministrationColors';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

const PAM_AUTHORITIES = [
  Authority.PAM_INTRODUCTION_R,
  Authority.PAM_INTRODUCTION_U,
  Authority.PAM_ITEM_C,
  Authority.PAM_ITEM_R,
  Authority.PAM_ITEM_U,
  Authority.PAM_ITEM_D,
  Authority.PAM_ITEM_V,
  Authority.PAM_DOCUMENT_C,
  Authority.PAM_DOCUMENT_R,
  Authority.PAM_DOCUMENT_U,
  Authority.PAM_SOURCES_C,
  Authority.PAM_SOURCES_R,
  Authority.PAM_SOURCES_U,
  Authority.PAM_SOURCES_D,
  Authority.PAM_SHARED_DOCUMENT_C,
  Authority.PAM_SHARED_DOCUMENT_R,
  Authority.PAM_SHARED_DOCUMENT_U,
];

/**
 * Menu items for the PAM module
 */
export const PamMenuItems = () => {
  return (
    <MainMenuItem
      to={Routes.AppPam}
      icon={<FontAwesomeIcon icon={faBookSection} />}
      className={ProjectAdministrationColors.borderColor}
      text={<FormattedMessage id="menu_pam" defaultMessage="Policies and Measures" />}
      authorities={PAM_AUTHORITIES}
    >
      <MainMenuSecondaryItem
        to={Routes.AppPamIntroduction}
        text={<FormattedMessage id="menu_introduction" defaultMessage="Introduction" />}
        authorities={[Authority.PAM_INTRODUCTION_R, Authority.PAM_INTRODUCTION_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPamOverview}
        text={<FormattedMessage id="menu_overview" defaultMessage="Overview" />}
        authorities={[Authority.PAM_R]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPamEntries}
        text={<FormattedMessage id="menu_pam_entries" defaultMessage="Policies and measures" />}
        authorities={[
          Authority.PAM_ITEM_C,
          Authority.PAM_ITEM_R,
          Authority.PAM_ITEM_U,
          Authority.PAM_ITEM_D,
          Authority.PAM_ITEM_V,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPamDocuments}
        text={<FormattedMessage id="menu_related_documents" defaultMessage="Related documents" />}
        authorities={[Authority.PAM_DOCUMENT_C, Authority.PAM_DOCUMENT_R, Authority.PAM_DOCUMENT_U]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPamSources}
        text={<FormattedMessage id="menu_external_sources" defaultMessage="External sources" />}
        authorities={[
          Authority.PAM_SOURCES_C,
          Authority.PAM_SOURCES_R,
          Authority.PAM_SOURCES_U,
          Authority.PAM_SOURCES_D,
        ]}
      />
      <MainMenuSecondaryItem
        to={Routes.AppPamSharedDocuments}
        text={<FormattedMessage id="shared_documents" defaultMessage="Collaboration workspace" />}
        authorities={[
          Authority.PAM_SHARED_DOCUMENT_C,
          Authority.PAM_SHARED_DOCUMENT_R,
          Authority.PAM_SHARED_DOCUMENT_U,
        ]}
      />
    </MainMenuItem>
  );
};
