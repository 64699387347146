import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const IndiIntroductionPage = lazy(() =>
  import('../../pages/app/indicators/IndiIntroductionPage').then((module) => ({
    default: module['IndiIntroductionPage'],
  }))
);
const IndiDocumentsPage = lazy(() =>
  import('../../pages/app/indicators/IndiDocumentsPage').then((module) => ({
    default: module['IndiDocumentsPage'],
  }))
);
const IndiSharedDocumentsPage = lazy(() =>
  import('../../pages/app/indicators/IndiSharedDocumentsPage').then((module) => ({
    default: module['IndiSharedDocumentsPage'],
  }))
);
const IndiExternalSourcesPage = lazy(() =>
  import('../../pages/app/indicators/IndiExternalSourcesPage').then((module) => ({
    default: module['IndiExternalSourcesPage'],
  }))
);
const IndiGhgPrio1Page = lazy(() =>
  import('../../pages/app/indicators/IndiGhgPrio1Page').then((module) => ({
    default: module['IndiGhgPrio1Page'],
  }))
);
const IndiGhgAprioPage = lazy(() =>
  import('../../pages/app/indicators/IndiGhgAprioPage').then((module) => ({
    default: module['IndiGhgAprioPage'],
  }))
);
const IndiGhgSupplPage = lazy(() =>
  import('../../pages/app/indicators/IndiGhgSupplPage').then((module) => ({
    default: module['IndiGhgSupplPage'],
  }))
);
const IndiGhgOverviewPage = lazy(() =>
  import('../../pages/app/indicators/IndiGhgOverviewPage').then((module) => ({
    default: module['IndiGhgOverviewPage'],
  }))
);
const IndiOtherDataentryPage = lazy(() =>
  import('../../pages/app/indicators/IndiOtherDataentryPage').then((module) => ({
    default: module['IndiOtherDataentryPage'],
  }))
);
const IndiOtherOverviewPage = lazy(() =>
  import('../../pages/app/indicators/IndiOtherOverviewPage').then((module) => ({
    default: module['IndiOtherOverviewPage'],
  }))
);
const IndiPasDataentryPage = lazy(() =>
  import('../../pages/app/indicators/IndiPasDataentryPage').then((module) => ({
    default: module['IndiPasDataentryPage'],
  }))
);
const IndiPasOverviewPage = lazy(() =>
  import('../../pages/app/indicators/IndiPasOverviewPage').then((module) => ({
    default: module['IndiPasOverviewPage'],
  }))
);
const IndiCcaDataentryPage = lazy(() =>
  import('../../pages/app/indicators/IndiCcaDataentryPage').then((module) => ({
    default: module['IndiCcaDataentryPage'],
  }))
);
const IndiCcaOverviewPage = lazy(() =>
  import('../../pages/app/indicators/IndiCcaOverviewPage').then((module) => ({
    default: module['IndiCcaOverviewPage'],
  }))
);

/**
 * Contains the route definitions for the indicators module administration
 */
export const indiRoutes: Route = {
  name: 'Indicators',
  path: getLastRoutePart(Routes.AppIndi),
  exact: true,
  routes: [
    {
      name: 'Indi introduction',
      path: getLastRoutePart(Routes.AppIndiIntroduction),
      exact: true,
      component: IndiIntroductionPage,
      routes: [],
    },
    {
      name: 'Indi documents',
      path: getLastRoutePart(Routes.AppIndiDocuments),
      exact: true,
      component: IndiDocumentsPage,
      routes: [],
    },
    {
      name: 'Indi shared documents',
      path: getLastRoutePart(Routes.AppIndiSharedDocuments),
      exact: true,
      component: IndiSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'Indi external sources',
      path: getLastRoutePart(Routes.AppIndiSources),
      exact: true,
      component: IndiExternalSourcesPage,
      routes: [],
    },
    {
      name: 'Indi ghg',
      path: getLastRoutePart(Routes.AppIndiGhg),
      exact: true,
      routes: [
        {
          name: 'Indi ghg overview',
          path: getLastRoutePart(Routes.AppIndiGhgOverview),
          exact: true,
          component: IndiGhgOverviewPage,
          routes: [],
        },
        {
          name: 'Indi ghg prio 1',
          path: getLastRoutePart(Routes.AppIndiGhgPrio1),
          exact: true,
          component: IndiGhgPrio1Page,
          routes: [],
        },
        {
          name: 'Indi ghg additonal prio',
          path: getLastRoutePart(Routes.AppIndiGhgAprio),
          exact: true,
          component: IndiGhgAprioPage,
          routes: [],
        },
        {
          name: 'Indi ghg suppl',
          path: getLastRoutePart(Routes.AppIndiGhgSuppl),
          exact: true,
          component: IndiGhgSupplPage,
          routes: [],
        },
      ],
    },
    {
      name: 'Indi other',
      path: getLastRoutePart(Routes.AppIndiOther),
      exact: true,
      routes: [
        {
          name: 'Indi other overview',
          path: getLastRoutePart(Routes.AppIndiOtherOverview),
          exact: true,
          component: IndiOtherOverviewPage,
          routes: [],
        },
        {
          name: 'Indi other data entry',
          path: getLastRoutePart(Routes.AppIndiOtherData),
          exact: true,
          component: IndiOtherDataentryPage,
          routes: [],
        },
      ],
    },
    {
      name: 'Indi pas',
      path: getLastRoutePart(Routes.AppIndiPas),
      exact: true,
      routes: [
        {
          name: 'Indi pas overview',
          path: getLastRoutePart(Routes.AppIndiPasOverview),
          exact: true,
          component: IndiPasOverviewPage,
          routes: [],
        },
        {
          name: 'Indi pas data entry',
          path: getLastRoutePart(Routes.AppIndiPasData),
          exact: true,
          component: IndiPasDataentryPage,
          routes: [],
        },
      ],
    },
    {
      name: 'Indi cca',
      path: getLastRoutePart(Routes.AppIndiCca),
      exact: true,
      routes: [
        {
          name: 'Indi cca overview',
          path: getLastRoutePart(Routes.AppIndiCcaOverview),
          exact: true,
          component: IndiCcaOverviewPage,
          routes: [],
        },
        {
          name: 'Indi cca data entry',
          path: getLastRoutePart(Routes.AppIndiCcaData),
          exact: true,
          component: IndiCcaDataentryPage,
          routes: [],
        },
      ],
    },
  ],
};
