import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const PasIntroductionPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasIntroductionPage').then((module) => ({
    default: module['PasIntroductionPage'],
  }))
);
const PasDocumentsPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasDocumentsPage').then((module) => ({
    default: module['PasDocumentsPage'],
  }))
);
const PasSharedDocumentsPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasSharedDocumentsPage').then((module) => ({
    default: module['PasSharedDocumentsPage'],
  }))
);
const PasExternalSourcesPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasExternalSourcesPage').then((module) => ({
    default: module['PasExternalSourcesPage'],
  }))
);
const PasImportPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasImportPage').then((module) => ({
    default: module['PasImportPage'],
  }))
);
const PasScenariosPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasScenariosPage').then((module) => ({
    default: module['PasScenariosPage'],
  }))
);
const PasScenariosChartPage = lazy(() =>
  import('../../pages/app/projections-and-scenarios/PasScenariosChartPage').then((module) => ({
    default: module['PasScenariosChartPage'],
  }))
);

/**
 * Contains the route definitions for the PAS module administration
 */
export const pasRoutes: Route = {
  name: 'Projections and Scenarios',
  path: getLastRoutePart(Routes.AppPas),
  exact: true,
  routes: [
    {
      name: 'PAS introduction',
      path: getLastRoutePart(Routes.AppPasIntroduction),
      exact: true,
      component: PasIntroductionPage,
      routes: [],
    },
    {
      name: 'PAS documents',
      path: getLastRoutePart(Routes.AppPasDocuments),
      exact: true,
      component: PasDocumentsPage,
      routes: [],
    },
    {
      name: 'PAS shared documents',
      path: getLastRoutePart(Routes.AppPasSharedDocuments),
      exact: true,
      component: PasSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'PAS external sources',
      path: getLastRoutePart(Routes.AppPasSources),
      exact: true,
      component: PasExternalSourcesPage,
      routes: [],
    },
    {
      name: 'PAS import',
      path: getLastRoutePart(Routes.AppPasImport),
      exact: true,
      component: PasImportPage,
      routes: [],
    },
    {
      name: 'PAS scenarios',
      path: getLastRoutePart(Routes.AppPasScenarios),
      exact: true,
      component: PasScenariosPage,
      routes: [],
    },
    {
      name: 'PAS scenarios chart',
      path: getLastRoutePart(Routes.AppPasScenarioChart),
      exact: true,
      component: PasScenariosChartPage,
      routes: [],
    },
  ],
};
