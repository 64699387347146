import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useCurrentLanguage } from '@oceanjs/international';
import { useExternalApplicationsQuery } from '../../../../../generated/graphql';
import { faSquareArrowUpRight } from '../../../../../lib/fontawesome/pro-light';
import { Routes } from '../../../../../routes/Routes';
import { MainMenuItem } from './MainMenuItem';
import { MainMenuSecondaryItem } from './MainMenuSecondaryItem';

/**
 * Menu items for external applications
 */
export const ExternalApplicationsMenuItems = () => {
  const language = useCurrentLanguage();
  const { data, loading, error } = useExternalApplicationsQuery({ variables: { language } });

  if (loading || error) {
    return null;
  }

  return (
    <MainMenuItem
      to={Routes.AppExternalApplications}
      icon={<FontAwesomeIcon icon={faSquareArrowUpRight} />}
      className="border-gray-200"
      text={
        <FormattedMessage id="menu_external_applications" defaultMessage="External applications" />
      }
      authorities={[]}
    >
      {data?.externalApplications.map((app) => (
        <MainMenuSecondaryItem
          key={app.id}
          to={`${Routes.AppExternalApplications}/show/${app.id}`}
          text={app.preferredNameTranslation?.translation}
          authorities={[]}
        />
      ))}
    </MainMenuItem>
  );
};
