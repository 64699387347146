import { createSlice, PayloadAction, useSelector } from '@oceanjs/international';
import { RootState } from './reducer';

interface PublicMenuState {
  visible: boolean;
}

const initialState: PublicMenuState = {
  visible: false,
};

const publicMenuSlice = createSlice({
  name: 'publicMenu',
  initialState,
  reducers: {
    showPublicMenu: (state, _action: PayloadAction) => {
      state.visible = true;
    },
    hidePublicMenu: (state, _action: PayloadAction) => {
      state.visible = false;
    },
  },
});

/**
 * Public menu state actions
 */
export const { showPublicMenu, hidePublicMenu } = publicMenuSlice.actions;
/**
 * Public menu state reducer
 */
export const publicMenuReducer = publicMenuSlice.reducer;

/**
 * Returns true if the public menu is currently visible
 * @returns Visiblity of public menu
 */
export function useIsPublicMenuVisible() {
  return useSelector((state: RootState) => state.publicMenu.visible);
}
