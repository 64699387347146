import { Route } from '@oceanjs/international';
import { lazy } from 'react';
import { getLastRoutePart, Routes } from '../Routes';

const PamIntroductionPage = lazy(() =>
  import('../../pages/app/policies-and-measures/PamIntroductionPage').then((module) => ({
    default: module['PamIntroductionPage'],
  }))
);
const PamEntriesPage = lazy(() =>
  import('../../pages/app/policies-and-measures/PamEntriesPage').then((module) => ({
    default: module['PamEntriesPage'],
  }))
);
const PamDocumentsPage = lazy(() =>
  import('../../pages/app/policies-and-measures/PamDocumentsPage').then((module) => ({
    default: module['PamDocumentsPage'],
  }))
);
const PamSharedDocumentsPage = lazy(() =>
  import('../../pages/app/policies-and-measures/PamSharedDocumentsPage').then((module) => ({
    default: module['PamSharedDocumentsPage'],
  }))
);
const PamExternalSourcesPage = lazy(() =>
  import('../../pages/app/policies-and-measures/PamExternalSourcesPage').then((module) => ({
    default: module['PamExternalSourcesPage'],
  }))
);
const PamOverviewPage = lazy(() =>
  import('../../pages/app/policies-and-measures/PamOverviewPage').then((module) => ({
    default: module['PamOverviewPage'],
  }))
);

/**
 * Contains the route definitions for the PAM module administration
 */
export const pamRoutes: Route = {
  name: 'Policies and Measures',
  path: getLastRoutePart(Routes.AppPam),
  exact: true,
  routes: [
    {
      name: 'PAM introduction',
      path: getLastRoutePart(Routes.AppPamIntroduction),
      exact: true,
      component: PamIntroductionPage,
      routes: [],
    },
    {
      name: 'PAM overview',
      path: getLastRoutePart(Routes.AppPamOverview),
      exact: true,
      component: PamOverviewPage,
      routes: [],
    },
    {
      name: 'PAM entries',
      path: getLastRoutePart(Routes.AppPamEntries),
      exact: true,
      component: PamEntriesPage,
      routes: [],
    },
    {
      name: 'PAM documents',
      path: getLastRoutePart(Routes.AppPamDocuments),
      exact: true,
      component: PamDocumentsPage,
      routes: [],
    },
    {
      name: 'PAM shared documents',
      path: getLastRoutePart(Routes.AppPamSharedDocuments),
      exact: true,
      component: PamSharedDocumentsPage,
      routes: [],
    },
    {
      name: 'PAM external sources',
      path: getLastRoutePart(Routes.AppPamSources),
      exact: true,
      component: PamExternalSourcesPage,
      routes: [],
    },
  ],
};
