import { ChildrenProps, useDispatch } from '@oceanjs/international';
import { useLayoutEffect } from 'react';
import { setLogout, useUserState } from '../../state/userState';
import { unauthorizedObserver } from '../BackendClient';
import { LoginHandler } from './components/LoginHandler';
import { LogoutHandler } from './components/LogoutHandler';

/**
 * Provides user information to the app. Will try to autologin
 * on first load and otherwise will handle the login and logout events.
 */
export const UserProvider = (props: ChildrenProps) => {
  const { children } = props;

  const userstate = useUserState();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    const listener = () => {
      dispatch(setLogout());
    };

    unauthorizedObserver.addListener(listener);
    return () => {
      unauthorizedObserver.removeListener(listener);
    };
  }, [dispatch]);

  if (userstate === 'logging-in') {
    return <LoginHandler />;
  }

  if (userstate === 'logging-out') {
    return <LogoutHandler />;
  }

  return <>{children}</>;
};
