import { CurrentRoute, ManagedApp } from '@oceanjs/international';
import { Suspense } from 'react';
import { messages } from '../../i18n';
import { defaultLanguage } from '../../i18n/defaultLanguage';
import { routes } from '../../routes';
import { rootReducer } from '../../state/reducer';
import { BackendClient } from '../BackendClient';
import { DefaultThemeProvider } from '../DefaultThemeProvider';
import { LoadingSpinner } from '../LoadingSpinner';
import { UserProvider } from '../UserProvider';

/**
 * Force 24 hours 2 digit english format
 */
const customFormats = {
  date: {
    en: {
      hour12: false,
      hour: '2-digit',
    },
  },
} as const;

/**
 * Main entry point for the react app, adds managed app and additional components:
 *
 * - DefaultThemeProvider
 * - BackendClient
 * - UserProvider
 */
export const App = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ManagedApp
        routes={routes}
        defaultLocale={defaultLanguage}
        messages={messages}
        customFormats={customFormats}
        reducer={rootReducer}
      >
        <DefaultThemeProvider>
          <BackendClient>
            <UserProvider>
              <CurrentRoute loadingSpinner={<LoadingSpinner />} />
            </UserProvider>
          </BackendClient>
        </DefaultThemeProvider>
      </ManagedApp>
    </Suspense>
  );
};
